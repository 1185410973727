import React, {useContext, useState} from "react";
import {LanguageContext} from "../App";
import classes from "../styles/component_styles/contactUs.module.scss";
import Alert from "../components/Alert";
import axios from "axios";
import {Container} from "react-bootstrap";
import WriteReviewForm from "../components/WriteReviewForm";
import {Helmet} from "react-helmet";

function WriteReviewPage() {

    const languageContext = useContext(LanguageContext);

    const green = "GREEN";
    const red = "RED";
    const active = "ACTIVE";
    const inactive = "INACTIVE";

    const [alertStatus, setAlertStatus] = useState({
        status: inactive,
        type: green
    });

    const showAlert = async (message) => {
        if(message === "Message Sent!"){
            setAlertStatus({
                status: active,
                type: green
            });
            setTimeout(() => {
                setAlertStatus({
                    status: inactive,
                    type: green
                });
            }, "5000");
        }
        else{
            setAlertStatus({
                status: active,
                type: red
            });
            setTimeout(() => {
                setAlertStatus({
                    status: inactive,
                    type: red
                });
            }, "5000");
        }
    }

    const AlertStatus = () =>{
        if(alertStatus.status === active){
            return(
                <Alert type={alertStatus.type}/>
            );
        }
    }

    const onSubmitReview = (reviewName, reviewNumber, reviewEmail, reviewDescription, photo, reviewStars) => {
        if((reviewName != "") && (reviewNumber != "") && (reviewEmail != "") && (reviewDescription != "") && (photo.file != null) && (reviewStars != 0)) {
            const formData = new FormData();

            formData.append('name', reviewName);
            formData.append('number', reviewNumber);
            formData.append('email', reviewEmail);
            formData.append('description', reviewDescription);
            formData.append('file', photo.file);
            formData.append('stars', reviewStars);

            axios.post(`https://usa-handyman.com/submitreview`, formData)
                .then(res => {
                    console.log(res);
                    showAlert(res.data.message)
                })
                .catch((error) => {
                    console.log(error);
                    showAlert("Error!");
                });
        }
    }

    return(
        <>

            <Helmet>
                <title>Write a Review of Home Remodeling Services in Charlotte NC</title>
                <meta
                    name="description"
                    content="Write a review of our home remodeling services in Charlotte NC. Share your experience with home renovations. Help others make informed decisions!"
                />
            </Helmet>

            <Container className={classes.layout}>
                <h2>{languageContext.contactUs.reviewTitle}</h2>

                <div className={classes.form}>
                    <WriteReviewForm languageContext={languageContext} onSubmitReview={onSubmitReview}/>
                </div>

                <AlertStatus />

            </Container>
        </>
    );
}

export default WriteReviewPage;