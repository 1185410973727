import React, {useContext} from "react";
import {LanguageContext} from "../App";
import classes from "../styles/component_styles/selectStars.module.scss";
import {AiFillStar} from "react-icons/ai";

function SelectStars(props) {

    const languageContext = useContext(LanguageContext);

    const starClick = (e) => {
        let element = e.target;
        while (element.tagName != "DIV"){
            element = element.parentNode;
        }

        let number = element.id;
        number = parseInt(number.slice(-1));
        props.setReviewStars(number);
    }

    const StarActive = (props) => {
        return(
            <div className={classes.starContainer} id={props.id} onClick={(e) => starClick(e)}>
                <AiFillStar className={classes.starActive}/>
            </div>
        );
    }

    const StarInactive = (props) => {
        return(
            <div className={classes.starContainer} id={props.id} onClick={(e) => starClick(e)}>
                <AiFillStar className={classes.starInactive}/>
            </div>
        );
    }

    const Stars = () => {
        const stars = [];

        for (let i = 1; i <= 5; i++) {
            if(i <= props.reviewStars) {
                stars.push(<StarActive id={"star"+i}/>);
            }
            else {
                stars.push(<StarInactive id={"star"+i}/>)
            }
        }

        return(stars);
    }

    return(
        <>
            <div className={classes.stars}>
                <Stars/>
            </div>
            <p className={classes.starsTip}>{languageContext.reviews.starDescription}</p>
        </>
    );
}

export default SelectStars;