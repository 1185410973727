import React from "react";
import ContactUs from "../components/ContactUs";
import ContactInfo from "../components/ContactInfo";
import {Container} from "react-bootstrap";
import {Helmet} from "react-helmet";

function ContactUsPage() {
    return(
        <>

            <Helmet>
                <title>Contact Us for Home Remodeling Services in Charlotte NC</title>
                <meta
                    name="description"
                    content="Contact us for premier home remodeling services in Charlotte NC. Our expert team handles kitchen, bathroom renovations, and more. Get in touch today!"
                />
            </Helmet>

            <Container>
                <ContactUs/>

                <ContactInfo/>
            </Container>
        </>
    );
}

export default ContactUsPage;