import React, {useContext} from 'react';
import {LanguageContext} from "../App";
import classes from "../styles/pages/servicePage.module.scss";
import {Col, Container, Row} from "react-bootstrap";
import {BsDoorOpenFill, BsWindows} from "react-icons/bs";
import {GiBrickWall, GiFlatPlatform} from "react-icons/gi";
import {FaBath, FaBrush, FaHome, FaHouseDamage, FaPaintRoller, FaPencilRuler, FaShower} from "react-icons/fa";
import {IoIosWater} from "react-icons/io";
import {IoBuild} from "react-icons/io5";
import {MdCountertops, MdKitchen} from "react-icons/md";
import {paths} from "../paths";
import {NavLink} from "react-router-dom";
import {Helmet} from "react-helmet";

function ServicesPage() {

    const languageContext = useContext(LanguageContext);

    return(
        <>

            <Helmet>
                <title>Kitchen and Bath Remodeling Services in Charlotte NC</title>
                <meta
                    name="description"
                    content="Premier kitchen and bathroom remodeling services in Charlotte NC. Expert solutions for home improvement needs. Contact us today for top-notch renovations!"
                />
            </Helmet>

            <h1>{languageContext.servicesPage.title}</h1>

            <Container>
                <h3 className={classes.subtitle}>{languageContext.servicesPage.commonServices}</h3>
                <Row>
                    <Col xl={4} lg={4} md={4} sm={6} xs={6}>
                        <NavLink to={paths.services + paths.residentialRemodeling}>
                            <FaHome className={classes.servicesIcon}/>
                            <p className={classes.servicesText}>{languageContext.servicesPage.residentialRemodeling}</p>
                        </NavLink>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={6} xs={6}>
                        <NavLink to={paths.services + paths.doors}>
                            <BsDoorOpenFill className={classes.servicesIcon}/>
                            <p className={classes.servicesText}>{languageContext.servicesPage.doors}</p>
                        </NavLink>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={6} xs={6}>
                        <NavLink to={paths.services + paths.windows}>
                            <BsWindows className={classes.servicesIcon}/>
                            <p className={classes.servicesText}>{languageContext.servicesPage.windows}</p>
                        </NavLink>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={6} xs={6}>
                        <NavLink to={paths.services + paths.flooring}>
                            <GiFlatPlatform className={classes.servicesIcon}/>
                            <p className={classes.servicesText}>{languageContext.servicesPage.flooring}</p>
                        </NavLink>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={6} xs={6}>
                        <NavLink to={paths.services + paths.painting}>
                            <FaPaintRoller className={classes.servicesIcon}/>
                            <p className={classes.servicesText}>{languageContext.servicesPage.painting}</p>
                        </NavLink>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={6} xs={6}>
                        <NavLink to={paths.services + paths.drywall}>
                            <FaBrush className={classes.servicesIcon}/>
                            <p className={classes.servicesText}>{languageContext.servicesPage.drywall}</p>
                        </NavLink>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={6} xs={6}>
                        <NavLink to={paths.services + paths.wallpaper}>
                            <GiBrickWall className={classes.servicesIcon}/>
                            <p className={classes.servicesText}>{languageContext.servicesPage.wallpaper}</p>
                        </NavLink>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={6} xs={6}>
                        <NavLink to={paths.services + paths.carpentry}>
                            <FaPencilRuler className={classes.servicesIcon}/>
                            <p className={classes.servicesText}>{languageContext.servicesPage.carpentry}</p>
                        </NavLink>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={6} xs={6}>
                        <NavLink to={paths.services + paths.homeImprovement}>
                            <IoBuild className={classes.servicesIcon}/>
                            <p className={classes.servicesText}>{languageContext.servicesPage.homeImprovement}</p>
                        </NavLink>
                    </Col>
                </Row>
                <h3 className={classes.subtitle}>{languageContext.servicesPage.bathroom}</h3>
                <Row>
                    <Col>
                        <NavLink to={paths.services + paths.bathroomRemodeling}>
                            <FaBath className={classes.servicesIcon}/>
                            <p className={classes.servicesText}>{languageContext.servicesPage.bathroomRemodeling}</p>
                        </NavLink>
                    </Col>
                    <Col>
                        <NavLink to={paths.services + paths.showerPan}>
                            <FaShower className={classes.servicesIcon}/>
                                <p className={classes.servicesText}>{languageContext.servicesPage.showerPan}</p>
                        </NavLink>
                    </Col>
                    <Col>
                        <NavLink to={paths.services + paths.waterDamageRestoration}>
                            <FaHouseDamage className={classes.servicesIcon}/>
                            <p className={classes.servicesText}>{languageContext.servicesPage.waterDamageRestoration}</p>
                        </NavLink>
                    </Col>
                </Row>
                <h3 className={classes.subtitle}>{languageContext.servicesPage.kitchen}</h3>
                <Row>
                    <Col>
                        <NavLink to={paths.services + paths.kitchenRemodeling}>
                            <MdKitchen className={classes.servicesIcon}/>
                            <p className={classes.servicesText}>{languageContext.servicesPage.kitchenRemodeling}</p>
                        </NavLink>
                    </Col>
                    <Col>
                        <NavLink to={paths.services + paths.sink}>
                            <IoIosWater className={classes.servicesIcon}/>
                            <p className={classes.servicesText}>{languageContext.servicesPage.sink}</p>
                        </NavLink>
                    </Col>
                    <Col>
                        <NavLink to={paths.services + paths.countertopInstallation}>
                            <MdCountertops className={classes.servicesIcon}/>
                            <p className={classes.servicesText}>{languageContext.servicesPage.countertopInstallation}</p>
                        </NavLink>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ServicesPage;