import React, {useContext} from 'react';
import {LanguageContext} from "../App";
import classes from "../styles/component_styles/header.module.scss";
import logo from '../images/logo.PNG';
import {Container} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import {paths} from "../paths";
import {IoChatbubbleEllipses} from "react-icons/io5";

function Header() {

    const languageContext = useContext(LanguageContext);

    return(
        <>
            <a name="top"></a>
            <Container fluid className={`${classes.header_layout} container-fluid`}>
                <img src={logo} className={classes.logo}/>
                <div className={classes.links}>
                    <NavLink to={paths.homePage} className={({isActive}) => (isActive ? classes.menu_btn_active : classes.menu_btn_inactive)}>
                        {languageContext.header.home}
                    </NavLink>
                    <NavLink to={paths.services} className={({isActive}) => (isActive ? classes.menu_btn_active : classes.menu_btn_inactive)}>
                        {languageContext.header.services}
                    </NavLink>
                    <NavLink to={paths.portfolio} className={({isActive}) => (isActive ? classes.menu_btn_active : classes.menu_btn_inactive)}>
                        {languageContext.header.portfolio}
                    </NavLink>
                    <NavLink to={paths.about} className={({isActive}) => (isActive ? classes.menu_btn_active : classes.menu_btn_inactive)}>
                        {languageContext.header.about}
                    </NavLink>
                </div>
                <NavLink to={paths.contact} className={classes.contact}>
                    <IoChatbubbleEllipses />
                </NavLink>
            </Container>
        </>
    );
}

export default Header;