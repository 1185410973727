import React, {useContext} from 'react';
import {LanguageContext} from "../App";
import classes from "../styles/component_styles/footer.module.scss";
import logo from '../images/logo.PNG';
import instagram from '../images/instagram.png';
import tiktok from '../images/tiktok.png';
import {Col, Container, Row} from "react-bootstrap";
import {BsArrowUpCircleFill} from "react-icons/bs";

function Footer() {

    const languageContext = useContext(LanguageContext);

    return(
        <>
            <hr/>
            <Container className={classes.footer_layout}>
                <Row>
                    <Col xxl={4} xl={4} lg={4} md={3} sm={3} xs={3}>
                        <img src={logo} alt={"logo"} className={classes.logo}/>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={6} sm={6} xs={6}>
                        <p className={classes.footer_description}>
                            {languageContext.footer.area}<br/>
                            {languageContext.footer.sms} - <a href="sms:9803830161">9803830161</a><br/>
                            {languageContext.footer.email} - <a href="mailto:usahandyman.com@gmail.com?subject = usa-handyman.com">usahandyman</a><br/>
                            {languageContext.footer.developer} - <a href="tel:9803831885">9803831885</a><br/>
                            Usa Dream Remodeling
                        </p>
                        <div className={classes.mediaLinks}>
                            <a href="https://www.instagram.com/aleksandr_semukhin_usa/" target="_blank"><img src={instagram} className={classes.mediaIcons}/></a>
                            <a href="https://www.tiktok.com/@www.usahandyman.com" target="_blank"><img src={tiktok} className={classes.mediaIcons}/></a>
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={3} sm={3} xs={3}>
                        <a href="#top" className={classes.arrow}>
                            <BsArrowUpCircleFill/>
                        </a>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Footer;