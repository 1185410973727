import React, {useContext} from "react";
import {LanguageContext} from "../../App";
import classes from "../../styles/component_styles/services.module.scss";
import image from "../../images/services/Sink.jpg";
import {IoIosWater} from "react-icons/io";
import {Col, Container, Row} from "react-bootstrap";
import {Helmet} from "react-helmet";

function SinkPage() {

    const languageContext = useContext(LanguageContext);

    return(
        <>

            <Helmet>
                <title>Sink Installation Services in Charlotte NC</title>
                <meta
                    name="description"
                    content="Explore expert sink installation services in Charlotte NC. Our skilled team ensures quality installations for your kitchen or bathroom renovation needs."
                />
            </Helmet>

            <div className={classes.start_image}>
                <h1 className={classes.start_message}><IoIosWater className={classes.servicesIcon}/> {languageContext.services.SinkPage.title}</h1>
                <p className={classes.darkText}>{languageContext.services.SinkPage.description}</p>
            </div>

            <Container>
                <Row>
                    <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                        <p className={classes.description}>
                            {languageContext.services.SinkPage.text1}
                            <br/><br/>
                            <span>{languageContext.services.SinkPage.text2}</span>
                            <br/>
                            {languageContext.services.SinkPage.text3}
                            <br/><br/>
                            <span>{languageContext.services.SinkPage.text4}</span>
                            <br/>
                            {languageContext.services.SinkPage.text5}
                            <br/><br/>
                            <span>{languageContext.services.SinkPage.text6}</span>
                            <br/>
                            {languageContext.services.SinkPage.text7}
                            <br/><br/>
                            <span>{languageContext.services.SinkPage.text8}</span>
                            <br/>
                            {languageContext.services.SinkPage.text9}
                            <br/><br/>
                            <span>{languageContext.services.SinkPage.text10}</span>
                            <br/>
                            {languageContext.services.SinkPage.text11}
                        </p>
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                        <img src={image} className={classes.image}/>
                    </Col>
                </Row>
            </Container>
        </>
    );

}

export default SinkPage;