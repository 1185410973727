import React, {useContext, useState} from 'react';
import {LanguageContext} from "../App";
import classes from "../styles/component_styles/contactUs.module.scss";
import {Col, Container, Row} from "react-bootstrap";
import axios from "axios";
import Form from "./Form";
import Alert from "./Alert";

function ContactUs() {

    const languageContext = useContext(LanguageContext);

    const appointment = "APPOINTMENT";
    const estimate = "ESTIMATE";
    const green = "GREEN";
    const red = "RED";
    const active = "ACTIVE";
    const inactive = "INACTIVE";

    const [selection, setSelection] = useState(appointment);
    const [alertStatus, setAlertStatus] = useState({
        status: inactive,
        type: green
    });

    const appointmentRef = React.createRef();
    const estimateRef = React.createRef();

    const showAlert = async (message) => {
        if(message === "Message Sent!"){
            setAlertStatus({
                status: active,
                type: green
            });
            setTimeout(() => {
                setAlertStatus({
                    status: inactive,
                    type: green
                });
            }, "5000");
        }
        else{
            setAlertStatus({
                status: active,
                type: red
            });
            setTimeout(() => {
                setAlertStatus({
                    status: inactive,
                    type: red
                });
            }, "5000");
        }
    }

    const AlertStatus = () =>{
        if(alertStatus.status === active){
            return(
                <Alert type={alertStatus.type}/>
            );
        }
    }

    const onSubmitAppointment = (appointmentName, appointmentNumber, appointmentDescription) => {
        if((appointmentName != "") && (appointmentNumber != "") && (appointmentDescription != "")) {
            axios.post(`https://usa-handyman.com/submitappointment`, {
                "name": appointmentName,
                "number": appointmentNumber,
                "description": appointmentDescription
            })
                .then(res => {
                    console.log(res);
                    showAlert(res.data.message);
                })
                .catch((error) => {
                    console.log(error);
                    showAlert("Error!");
                });
        }
    }

    const onSubmitEstimate = (estimateName, estimateNumber, estimateEmail, estimateDescription, photos) => {
        if((estimateName != "") && (estimateNumber != "") && (estimateEmail != "") && (estimateDescription != "")) {
            const formData = new FormData();

            for (const key in photos) {
                if (photos.hasOwnProperty(key)) {
                    const fileObject = photos[key].file;
                    formData.append('files', fileObject);
                }
            }

            formData.append('name', estimateName);
            formData.append('number', estimateNumber);
            formData.append('email', estimateEmail);
            formData.append('description', estimateDescription);

            axios.post(`https://usa-handyman.com/submitestimate`, formData)
                .then(res => {
                    console.log(res);
                    showAlert(res.data.message)
                })
                .catch((error) => {
                    console.log(error);
                    showAlert("Error!");
                });
        }
    }

    return(
        <>
            <a name="contact"></a>
            <Container className={classes.layout}>
                <h2>{languageContext.contactUs.title}</h2>
                <Row className={classes.selectRow}>
                    <Col onClick={() => {
                        if(selection != appointment){
                            appointmentRef.current.setAttribute("status", "active");
                            estimateRef.current.setAttribute("status", "inactive");
                            setSelection(appointment);
                        }
                    }} className={classes.selectCol}>
                        <p status={"active"}  ref={appointmentRef} className={classes.select}>{languageContext.contactUs.appointment}</p>
                    </Col>
                    <Col onClick={() => {
                        if(selection != estimate){
                            appointmentRef.current.setAttribute("status", "inactive");
                            estimateRef.current.setAttribute("status", "active");
                            setSelection(estimate);
                        }
                    }} className={classes.selectCol}>
                        <p status={"inactive"} ref={estimateRef} className={classes.select}>{languageContext.contactUs.estimate}</p>
                    </Col>
                </Row>

                <div className={classes.form}>
                    <Form languageContext={languageContext} selection={selection} appointment={appointment} onSubmitAppointment={onSubmitAppointment} onSubmitEstimate={onSubmitEstimate}/>
                </div>

                <AlertStatus />

            </Container>
        </>
    );
}

export default ContactUs;