import React from 'react';
import classes from "../styles/pages/portfolioPage.module.scss";
import image1 from '../images/portfolio/startPhotos/1.jpg';
import image2 from '../images/portfolio/startPhotos/2.jpg';
import image3 from '../images/portfolio/startPhotos/3.jpg';
import image4 from '../images/portfolio/startPhotos/4.jpg';
import image5 from '../images/portfolio/startPhotos/5.jpg';
import image6 from '../images/portfolio/startPhotos/6.jpg';
import {Carousel} from "react-bootstrap";

function HomeSlider() {

    return(
        <Carousel variant="dark">
            <Carousel.Item>
                <img src={image1} className={classes.carouselImages} loading="lazy"/>
            </Carousel.Item>
            <Carousel.Item>
                <img src={image2} className={classes.carouselImages} loading="lazy"/>
            </Carousel.Item>
            <Carousel.Item>
                <img src={image3} className={classes.carouselImages} loading="lazy"/>
            </Carousel.Item>
            <Carousel.Item>
                <img src={image4} className={classes.carouselImages} loading="lazy"/>
            </Carousel.Item>
            <Carousel.Item>
                <img src={image5} className={classes.carouselImages} loading="lazy"/>
            </Carousel.Item>
            <Carousel.Item>
                <img src={image6} className={classes.carouselImages} loading="lazy"/>
            </Carousel.Item>
        </Carousel>
    );
}

export default HomeSlider;