import React, {useContext} from "react";
import {LanguageContext} from "../App";
import classes from "../styles/pages/contactsPage.module.scss";
import {BsDot} from "react-icons/bs";
import {AiFillHome} from "react-icons/ai";

function ServiceArea() {

    const languageContext = useContext(LanguageContext);

    const AreaList = () => {
        let list = [];

        for(let area of languageContext.aboutPage.areas){
            list.push(<p key={area}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<BsDot className={classes.dot_name}/>{area}</p>);
        }

        return(list);
    }

    return(
        <div className={classes.infoBlock}>
            <h5><AiFillHome className={classes.icon_name}/> {languageContext.aboutPage.location}</h5>
            <AreaList/>
        </div>
    );
}

export default ServiceArea;