import React, {useContext} from "react";
import {LanguageContext} from "../App";
import classes from "../styles/component_styles/quote.module.scss";
import quoteImage from "../images/familyPhoto.jpg";
import {Col, Container, Row} from "react-bootstrap";

function Quote() {

    const languageContext = useContext(LanguageContext);

    return(
        <Container fluid className={classes.quote}>
            <Row>
                <Col xl={9} lg={8} md={7} sm={12} xs={12}>
                    <h2 className={classes.quoteTitle}>{languageContext.commonText.title}</h2>
                    <p className={classes.quoteText}>{languageContext.commonText.aboutMe}</p>
                </Col>
                <Col xl={3} lg={4} md={5} sm={12} xs={12}>
                    <img src={quoteImage} className={classes.quoteImage}/>
                </Col>
            </Row>
        </Container>
    );
}

export default Quote;