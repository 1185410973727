import React, {useContext} from 'react';
import {LanguageContext} from "../App";
import classes from "../styles/pages/contactsPage.module.scss";
import {Container} from "react-bootstrap";
import ContactInfo from "../components/ContactInfo";
import Quote from "../components/Quote";
import ServiceArea from "../components/ServiceArea";
import {Helmet} from "react-helmet";
import Certificate from "../components/Certificate";

function AboutPage() {

    const languageContext = useContext(LanguageContext);

    return(
        <>

            <Helmet>
                <title>About Handyman Home Improvement Services in Charlotte NC</title>
                <meta
                    name="description"
                    content="Learn about our home improvement services in Charlotte NC. Expert solutions for kitchen, bathroom remodeling, and more. Contact us for quality renovations!"
                />
            </Helmet>

            <h1>{languageContext.aboutPage.title}</h1>

            <Quote/>

            <Container className={classes.contacts_layout}>

                <h2 className={classes.sectionTitle}>Contact Info</h2>

                <ContactInfo/>

                <h2 className={classes.sectionTitle}>Service Area</h2>

                <ServiceArea/>

            </Container>

            <Certificate/>

        </>
    );
}

export default AboutPage;