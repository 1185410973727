import React from "react";
import classes from "../styles/component_styles/Alert.module.scss";
import {RxCheckCircled, RxCrossCircled} from "react-icons/rx";

function Alert(props) {
    if (props.type === "GREEN") {
        return(
            <div className={classes.alert} type={props.type}>
                <p className={classes.alertText} type={props.type}><RxCheckCircled className={classes.alertIcon}/> Message Sent!</p>
            </div>
        );
    }
    else{
        return(
            <div className={classes.alert} type={props.type}>
                <p className={classes.alertText} type={props.type}><RxCrossCircled className={classes.alertIcon}/> Error!</p>
            </div>
        );
    }
}

export default Alert;