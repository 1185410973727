import React, {useContext} from "react";
import {LanguageContext} from "../App";
import {paths} from "../paths";
import classes from "../styles/component_styles/reviews.module.scss";
import elements from "../styles/settings/elements.module.scss";
import Review from "../components/Review";
import {FaHeart} from "react-icons/fa";
import {Col, Container, Row} from "react-bootstrap";
import {BiSolidMessageSquareEdit} from "react-icons/bi";
import {NavLink} from "react-router-dom";
import {Helmet} from "react-helmet";

function ReviewsPage() {
    const languageContext = useContext(LanguageContext);

    const ReviewsList = () => {
        const list = [];
        const array = languageContext.reviews.list;

        for (let review of array) {
            list.push(<Review name={review.name} stars={review.stars} description={review.description} image={review.image}/>);
        }

        return(list);
    }

    return(
        <>

            <Helmet>
                <title>Reviews of Home Remodeling Services in Charlotte NC</title>
                <meta
                    name="description"
                    content="Explore reviews of our home remodeling services in Charlotte NC. Discover expert solutions for kitchen, bathroom, and more. Find out what our clients say!"
                />
            </Helmet>

            <h2 className={classes.reviewsTitle}>{languageContext.reviews.title} <FaHeart className={classes.heart}/></h2>

            <div>
                <ReviewsList/>

                <Container className={classes.reviewButtons}>
                    <Row>
                        <Col>
                            <NavLink to={paths.writeReview}>
                                <button className={elements.blue_button}>Write a Review <BiSolidMessageSquareEdit className={classes.buttonIcon}/></button>
                            </NavLink>
                        </Col>
                    </Row>


                </Container>

            </div>
        </>
    );
}

export default ReviewsPage;