import React, {useEffect} from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ContactUs from "../components/ContactUs";
import {Helmet} from "react-helmet";

function ContactPage({children}) {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [children]);

    return(
        <>

            <Helmet>
                <title>Contact Us for Home Remodeling Services in Charlotte NC</title>
            </Helmet>

            <Header/>

            {children}

            <ContactUs/>

            <Footer/>
        </>
    )
}

export default ContactPage;