import React from "react";
import classes from "../styles/component_styles/reviews.module.scss";
import {AiFillStar} from "react-icons/ai";

function ReviewStars(props) {

    const StarActive = () => {
        return(
            <AiFillStar className={classes.starActive}/>
        );
    }

    const StarInactive = () => {
        return(
            <AiFillStar className={classes.starInactive}/>
        );
    }

    const Stars = () => {
        const stars = [];

        for (let i = 1; i <= 5; i++) {
            if(i <= props.stars) {
                stars.push(<StarActive/>);
            }
            else {
                stars.push(<StarInactive/>)
            }
        }

        return(stars);
    }

    return(
        <div className={classes.stars}>
            <Stars/>
        </div>
    );
}

export default ReviewStars;