import React from 'react';
import {languages} from "./storage/languages";
import {paths} from "./paths";
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/settings/app.scss';
import {Route, Routes} from "react-router-dom";
import HomePage from "./pages/HomePage";
import PortfolioPage from "./pages/PortfolioPage";
import ServicesPage from "./pages/ServicesPage";
import BathroomRemodelingPage from "./pages/services/BathroomRemodelingPage";
import CarpentryPage from "./pages/services/CarpentryPage";
import CountertopInstallationPage from "./pages/services/CountertopInstallationPage";
import DoorsPage from "./pages/services/DoorsPage";
import DrywallPage from "./pages/services/DrywallPage";
import FlooringPage from "./pages/services/FlooringPage";
import HomeImprovementPage from "./pages/services/HomeImprovementPage";
import KitchenRemodelingPage from "./pages/services/KitchenRemodelingPage";
import PaintingPage from "./pages/services/PaintingPage";
import ShowerpanPage from "./pages/services/ShowerpanPage";
import SinkPage from "./pages/services/SinkPage";
import WaterDamageRestorationPage from "./pages/services/WaterDamageRestorationPage";
import WindowsPage from "./pages/services/WindowsPage";
import AboutPage from "./pages/AboutPage";
import Page from "./pages/Page";
import ContactPage from "./pages/ContactPage";
import ContactUsPage from "./pages/ContactUsPage";
import ResidentialRemodelingPage from "./pages/services/ResidentialRemodelingPage";
import ReviewsPage from "./pages/ReviewsPage";
import WriteReviewPage from "./pages/WriteReviewPage";
import WallpaperPage from "./pages/services/WallpaperPage";

export const LanguageContext = React.createContext({});

function App() {

        let userLang = (navigator.language || navigator.userLanguage).slice(0, 2);
        if((userLang !== "en") && (userLang !== "ru") && (userLang !== "es")){
            userLang = "en";
        }

        let langText;
        if(userLang === "ru"){
            langText = languages.ru;
        }
        else if(userLang === "es"){
            langText = languages.es;
        }
        else{
            langText = languages.en;
        }

  return (
      <LanguageContext.Provider value={langText}>
              <Routes>
                  <Route path={paths.homePage} element={<Page><HomePage/></Page>} />
                  <Route path={paths.services} element={<Page><ServicesPage/></Page>} />
                  <Route path={paths.portfolio} element={<ContactPage><PortfolioPage/></ContactPage>} />
                  <Route path={paths.about} element={<Page><AboutPage/></Page>} />
                  <Route path={paths.contact} element={<Page><ContactUsPage/></Page>} />
                  <Route path={paths.reviews} element={<Page><ReviewsPage/></Page>} />
                  <Route path={paths.writeReview} element={<Page><WriteReviewPage/></Page>} />

                  <Route path={paths.services + paths.residentialRemodeling} element={<ContactPage><ResidentialRemodelingPage/></ContactPage>} />
                  <Route path={paths.services + paths.bathroomRemodeling} element={<ContactPage><BathroomRemodelingPage/></ContactPage>} />
                  <Route path={paths.services + paths.carpentry} element={<ContactPage><CarpentryPage/></ContactPage>} />
                  <Route path={paths.services + paths.countertopInstallation} element={<ContactPage><CountertopInstallationPage/></ContactPage>} />
                  <Route path={paths.services + paths.doors} element={<ContactPage><DoorsPage/></ContactPage>} />
                  <Route path={paths.services + paths.drywall} element={<ContactPage><DrywallPage/></ContactPage>} />
                  <Route path={paths.services + paths.flooring} element={<ContactPage><FlooringPage/></ContactPage>} />
                  <Route path={paths.services + paths.homeImprovement} element={<ContactPage><HomeImprovementPage/></ContactPage>} />
                  <Route path={paths.services + paths.kitchenRemodeling} element={<ContactPage><KitchenRemodelingPage/></ContactPage>} />
                  <Route path={paths.services + paths.painting} element={<ContactPage><PaintingPage/></ContactPage>} />
                  <Route path={paths.services + paths.showerPan} element={<ContactPage><ShowerpanPage/></ContactPage>} />
                  <Route path={paths.services + paths.sink} element={<ContactPage><SinkPage/></ContactPage>} />
                  <Route path={paths.services + paths.waterDamageRestoration} element={<ContactPage><WaterDamageRestorationPage/></ContactPage>} />
                  <Route path={paths.services + paths.wallpaper} element={<ContactPage><WallpaperPage/></ContactPage>} />
                  <Route path={paths.services + paths.windows} element={<ContactPage><WindowsPage/></ContactPage>} />

                  <Route path={paths.undefined} element={<Page><HomePage/></Page>} />
              </Routes>
      </LanguageContext.Provider>
  );
}

export default App;
