import React, {useContext} from "react";
import {LanguageContext} from "../App";
import classes from "../styles/component_styles/reviews.module.scss";
import elements from "../styles/settings/elements.module.scss";
import {FaHeart} from "react-icons/fa";
import Review from "./Review";
import {Col, Container, Row} from "react-bootstrap";
import {BiSolidMessageSquareDetail, BiSolidMessageSquareEdit} from "react-icons/bi";
import {paths} from "../paths";
import {NavLink} from "react-router-dom";

function Reviews() {

    const languageContext = useContext(LanguageContext);

    const ReviewsList = () => {
        const list = [];
        const array = languageContext.reviews.list;

        for (let i = 0; i < 3; i++) {
            list.push(<Review name={array[i].name} stars={array[i].stars} description={array[i].description} image={array[i].image}/>);
        }

        return(list);
    }

    return(
        <div className={classes.reviews}>
            <h2 className={classes.reviewsTitle}>{languageContext.reviews.title} <FaHeart className={classes.heart}/></h2>

            <div>
                <ReviewsList/>

                <Container className={classes.reviewButtons}>
                    <Row>
                        <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                            <NavLink to={paths.reviews}>
                                <button className={elements.blue_button}>More Reviews <BiSolidMessageSquareDetail className={classes.buttonIcon}/></button>
                            </NavLink>
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                            <NavLink to={paths.writeReview}>
                                <button className={elements.blue_button}>Write a Review <BiSolidMessageSquareEdit className={classes.buttonIcon}/></button>
                            </NavLink>
                        </Col>
                    </Row>


                </Container>

            </div>
        </div>
    );
}

export default Reviews;