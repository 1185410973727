export const paths = {
    'homePage': '/',
    'services': '/services',
    'portfolio': '/portfolio-handyman-charlotte-nc',
    'about': '/about-handyman-charlotte-nc',
    'contact': '/contact-handyman-charlotte-nc',
    'reviews': '/reviews-charlotte-nc',
    'writeReview': '/write-review-charlotte-nc',
    'bathroomRemodeling': '/bathroom-remodeling-service-charlotte-nc',
    'wallpaper': '/wallpaper-service-charlotte-nc',
    'carpentry': '/carpentry-service-charlotte-nc',
    'countertopInstallation': '/countertop-installation-service-charlotte-nc',
    'doors': '/doors-installation-service-charlotte-nc',
    'drywall': '/drywall-service-charlotte-nc',
    'flooring': '/flooring-service-charlotte-nc',
    'homeImprovement': '/home-improvement-service-charlotte-nc',
    'kitchenRemodeling': '/kitchen-remodeling-service-charlotte-nc',
    'painting': '/painting-service-charlotte-nc',
    'residentialRemodeling': '/residential-remodeling-service-charlotte-nc',
    'showerPan': '/shower-pan-installation-service-charlotte-nc',
    'sink': '/sink-installation-service-charlotte-nc',
    'waterDamageRestoration': '/water-damage-restoration-service-charlotte-nc',
    'windows': '/windows-installation-service-charlotte-nc',
    'undefined': '*'
}