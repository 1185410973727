import React, {useEffect} from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

function Page({children}) {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [children]);

    return(
        <>
            <Header/>

            {children}

            <Footer/>
        </>
    )
}

export default Page;