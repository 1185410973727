import React, {useContext} from 'react';

import {Carousel} from "react-bootstrap";

import image1 from '../images/portfolio/startPhotos/1.jpg';
import image2 from '../images/portfolio/startPhotos/2.jpg';
import image3 from '../images/portfolio/startPhotos/3.jpg';
import image4 from '../images/portfolio/startPhotos/4.jpg';
import image5 from '../images/portfolio/startPhotos/5.jpg';
import image6 from '../images/portfolio/startPhotos/6.jpg';
import imageShowerpan1 from '../images/portfolio/showerpan/1.jpg';
import imageShowerpan2 from '../images/portfolio/showerpan/2.jpg';
import imageShowerpan3 from '../images/portfolio/showerpan/3.jpg';
import imageShowerpan4 from '../images/portfolio/showerpan/4.jpg';
import imageShowerpan5 from '../images/portfolio/showerpan/5.jpg';
import imageRemodeling1 from '../images/portfolio/remodeling/1.jpg';
import imageRemodeling2 from '../images/portfolio/remodeling/2.jpg';
import imageRemodeling3 from '../images/portfolio/remodeling/3.jpg';
import imageRemodeling4 from '../images/portfolio/remodeling/4.jpg';
import imageRemodeling5 from '../images/portfolio/remodeling/5.jpg';
import imageBacksplash1 from '../images/portfolio/backsplash/1.jpg';
import imageBacksplash2 from '../images/portfolio/backsplash/2.jpg';
import imageBacksplash3 from '../images/portfolio/backsplash/3.jpg';
import imageBacksplash4 from '../images/portfolio/backsplash/4.jpg';
import imageLadder1 from '../images/portfolio/ladder/1.jpg';
import imageLadder2 from '../images/portfolio/ladder/2.jpg';
import imageLadder3 from '../images/portfolio/ladder/3.jpg';
import imageLadder4 from '../images/portfolio/ladder/4.jpg';
import imageLadder5 from '../images/portfolio/ladder/5.jpg';
import imageLadder6 from '../images/portfolio/ladder/6.jpg';
import imageLadder7 from '../images/portfolio/ladder/7.jpg';
import imageLadder8 from '../images/portfolio/ladder/8.jpg';
import imageLadder9 from '../images/portfolio/ladder/9.jpg';
import imageProject1_1 from '../images/portfolio/projects/project1/1.jpg';
import imageProject1_2 from '../images/portfolio/projects/project1/2.jpg';
import imageProject1_3 from '../images/portfolio/projects/project1/3.jpg';
import imageProject1_4 from '../images/portfolio/projects/project1/4.jpg';
import imageProject1_5 from '../images/portfolio/projects/project1/5.jpg';
import imageProject2_1 from '../images/portfolio/projects/project2/1.jpg';
import imageProject2_2 from '../images/portfolio/projects/project2/2.jpg';
import imageProject2_3 from '../images/portfolio/projects/project2/3.jpg';
import imageProject2_4 from '../images/portfolio/projects/project2/4.jpg';
import imageProject2_5 from '../images/portfolio/projects/project2/5.jpg';
import imageProject2_6 from '../images/portfolio/projects/project2/6.jpg';
import imageProject2_7 from '../images/portfolio/projects/project2/7.jpg';
import imageProject2_8 from '../images/portfolio/projects/project2/8.jpg';
import imageProject2_9 from '../images/portfolio/projects/project2/9.jpg';
import imageProject2_10 from '../images/portfolio/projects/project2/10.jpg';
import imageProject2_11 from '../images/portfolio/projects/project2/11.jpg';
import imageProject2_12 from '../images/portfolio/projects/project2/12.jpg';
import imageProject2_13 from '../images/portfolio/projects/project2/13.jpg';
import imageProject3_1 from '../images/portfolio/projects/project3/1.jpg';
import imageProject3_2 from '../images/portfolio/projects/project3/2.jpg';
import imageProject3_3 from '../images/portfolio/projects/project3/3.jpg';
import imageProject3_4 from '../images/portfolio/projects/project3/4.jpg';
import imageProject3_5 from '../images/portfolio/projects/project3/5.jpg';
import imageBefore1_1 from '../images/portfolio/before_after/project1/1.jpg';
import imageBefore1_2 from '../images/portfolio/before_after/project1/2.jpg';
import imageBefore1_3 from '../images/portfolio/before_after/project1/3.jpg';
import imageBefore1_4 from '../images/portfolio/before_after/project1/4.jpg';
import imageBefore1_5 from '../images/portfolio/before_after/project1/5.jpg';
import imageBefore1_6 from '../images/portfolio/before_after/project1/6.jpg';
import imageBefore1_7 from '../images/portfolio/before_after/project1/7.jpg';
import imageBefore2_1 from '../images/portfolio/before_after/project2/1.jpg';
import imageBefore2_2 from '../images/portfolio/before_after/project2/2.jpg';
import imageBefore2_3 from '../images/portfolio/before_after/project2/3.jpg';
import imageBefore2_4 from '../images/portfolio/before_after/project2/4.jpg';
import imageBefore2_5 from '../images/portfolio/before_after/project2/5.jpg';
import imageBefore3_1 from '../images/portfolio/before_after/project3/1.jpg';
import imageBefore3_2 from '../images/portfolio/before_after/project3/2.jpg';
import imageBefore3_3 from '../images/portfolio/before_after/project3/3.jpg';
import imageBefore3_4 from '../images/portfolio/before_after/project3/4.jpg';
import imageBefore3_5 from '../images/portfolio/before_after/project3/5.jpg';
import imageBefore3_6 from '../images/portfolio/before_after/project3/6.jpg';
import imageBefore3_7 from '../images/portfolio/before_after/project3/7.jpg';
import imageBefore3_8 from '../images/portfolio/before_after/project3/8.jpg';
import imageBefore3_9 from '../images/portfolio/before_after/project3/9.jpg';
import imageBefore3_10 from '../images/portfolio/before_after/project3/10.jpg';
import imageBefore3_11 from '../images/portfolio/before_after/project3/11.jpg';
import imageBefore3_12 from '../images/portfolio/before_after/project3/12.jpg';
import imageBefore3_13 from '../images/portfolio/before_after/project3/13.jpg';
import imageBefore3_14 from '../images/portfolio/before_after/project3/14.jpg';
import imageBefore3_15 from '../images/portfolio/before_after/project3/15.jpg';
import imageBefore4_1 from '../images/portfolio/before_after/project4/1.jpg';
import imageBefore4_2 from '../images/portfolio/before_after/project4/2.jpg';
import imageBefore4_3 from '../images/portfolio/before_after/project4/3.jpg';
import imageBefore4_4 from '../images/portfolio/before_after/project4/4.jpg';
import imageBefore4_5 from '../images/portfolio/before_after/project4/5.jpg';
import imageBefore4_6 from '../images/portfolio/before_after/project4/6.jpg';
import imageBefore4_7 from '../images/portfolio/before_after/project4/7.jpg';
import imageBefore4_8 from '../images/portfolio/before_after/project4/8.jpg';
import imageBefore4_9 from '../images/portfolio/before_after/project4/9.jpg';
import imageBefore5_1 from '../images/portfolio/before_after/project5/1.jpg';
import imageBefore5_2 from '../images/portfolio/before_after/project5/2.jpg';
import imageBefore5_3 from '../images/portfolio/before_after/project5/3.jpg';
import imageBefore5_4 from '../images/portfolio/before_after/project5/4.jpg';
import imageBefore5_5 from '../images/portfolio/before_after/project5/5.jpg';
import imageBefore5_6 from '../images/portfolio/before_after/project5/6.jpg';
import imageBefore5_7 from '../images/portfolio/before_after/project5/7.jpg';
import imageBefore5_8 from '../images/portfolio/before_after/project5/8.jpg';
import imageBefore5_9 from '../images/portfolio/before_after/project5/9.jpg';
import imageBefore5_10 from '../images/portfolio/before_after/project5/10.jpg';
import imageBefore5_11 from '../images/portfolio/before_after/project5/11.jpg';
import imageBefore5_12 from '../images/portfolio/before_after/project5/12.jpg';
import imageBefore5_13 from '../images/portfolio/before_after/project5/13.jpg';
import imageBefore6_1 from '../images/portfolio/before_after/project6/1.jpg';
import imageBefore6_2 from '../images/portfolio/before_after/project6/2.jpg';
import imageBefore6_3 from '../images/portfolio/before_after/project6/3.jpg';
import imageBefore6_4 from '../images/portfolio/before_after/project6/4.jpg';
import imageBefore6_5 from '../images/portfolio/before_after/project6/5.jpg';
import imageBefore6_6 from '../images/portfolio/before_after/project6/6.jpg';
import imageBefore6_7 from '../images/portfolio/before_after/project6/7.jpg';
import imageBefore6_8 from '../images/portfolio/before_after/project6/8.jpg';
import imageBefore6_9 from '../images/portfolio/before_after/project6/9.jpg';
import imageBefore6_10 from '../images/portfolio/before_after/project6/10.jpg';
import imageBefore6_11 from '../images/portfolio/before_after/project6/11.jpg';
import imageBefore6_12 from '../images/portfolio/before_after/project6/12.jpg';
import imageBefore6_13 from '../images/portfolio/before_after/project6/13.jpg';
import imageBefore6_14 from '../images/portfolio/before_after/project6/14.jpg';
import imageBefore6_15 from '../images/portfolio/before_after/project6/15.jpg';
import imageBefore7_1 from '../images/portfolio/before_after/project7/1.jpg';
import imageBefore7_2 from '../images/portfolio/before_after/project7/2.jpg';
import imageBefore7_3 from '../images/portfolio/before_after/project7/3.jpg';
import imageBefore7_4 from '../images/portfolio/before_after/project7/4.jpg';
import imageBefore7_5 from '../images/portfolio/before_after/project7/5.jpg';
import imageBefore7_6 from '../images/portfolio/before_after/project7/6.jpg';
import imageBefore7_7 from '../images/portfolio/before_after/project7/7.jpg';
import imageBefore7_8 from '../images/portfolio/before_after/project7/8.jpg';
import imageBefore7_9 from '../images/portfolio/before_after/project7/9.jpg';

import imageBefore8_1 from '../images/portfolio/before_after/project8/1.jpg';
import imageBefore8_2 from '../images/portfolio/before_after/project8/2.jpg';
import imageBefore8_3 from '../images/portfolio/before_after/project8/3.jpg';
import imageBefore8_4 from '../images/portfolio/before_after/project8/4.jpg';
import imageBefore8_5 from '../images/portfolio/before_after/project8/5.jpg';
import imageBefore8_6 from '../images/portfolio/before_after/project8/6.jpg';
import imageBefore8_7 from '../images/portfolio/before_after/project8/7.jpg';
import imageBefore8_8 from '../images/portfolio/before_after/project8/8.jpg';
import imageBefore8_9 from '../images/portfolio/before_after/project8/9.jpg';
import imageBefore8_10 from '../images/portfolio/before_after/project8/10.jpg';
import imageBefore8_11 from '../images/portfolio/before_after/project8/11.jpg';
import imageBefore8_12 from '../images/portfolio/before_after/project8/12.jpg';
import imageBefore8_13 from '../images/portfolio/before_after/project8/13.jpg';
import imageBefore8_14 from '../images/portfolio/before_after/project8/14.jpg';
import imageBefore8_15 from '../images/portfolio/before_after/project8/15.jpg';
import imageBefore8_16 from '../images/portfolio/before_after/project8/16.jpg';
import imageBefore8_17 from '../images/portfolio/before_after/project8/17.jpg';
import imageBefore8_18 from '../images/portfolio/before_after/project8/18.jpg';
import imageBefore8_19 from '../images/portfolio/before_after/project8/19.jpg';
import imageBefore8_20 from '../images/portfolio/before_after/project8/20.jpg';
import imageBefore8_21 from '../images/portfolio/before_after/project8/21.jpg';
import imageBefore8_22 from '../images/portfolio/before_after/project8/22.jpg';
import imageBefore8_23 from '../images/portfolio/before_after/project8/23.jpg';
import imageBefore8_24 from '../images/portfolio/before_after/project8/24.jpg';
import imageBefore8_25 from '../images/portfolio/before_after/project8/25.jpg';
import laminate1 from '../images/portfolio/laminate/1.jpg';
import laminate2 from '../images/portfolio/laminate/2.jpg';
import laminate3 from '../images/portfolio/laminate/3.jpg';
import laminate4 from '../images/portfolio/laminate/4.jpg';
import imageBefore9_1 from '../images/portfolio/before_after/project9/1.jpg';
import imageBefore9_2 from '../images/portfolio/before_after/project9/2.jpg';
import imageBefore9_3 from '../images/portfolio/before_after/project9/3.jpg';
import imageBefore9_4 from '../images/portfolio/before_after/project9/4.jpg';
import imageBefore9_5 from '../images/portfolio/before_after/project9/5.jpg';

import imageBefore10_1 from '../images/portfolio/before_after/project10/1.jpg';
import imageBefore10_2 from '../images/portfolio/before_after/project10/2.jpg';
import imageBefore10_3 from '../images/portfolio/before_after/project10/3.jpg';
import imageBefore10_4 from '../images/portfolio/before_after/project10/4.jpg';
import imageBefore10_5 from '../images/portfolio/before_after/project10/5.jpg';
import imageBefore10_6 from '../images/portfolio/before_after/project10/6.jpg';
import imageBefore10_7 from '../images/portfolio/before_after/project10/7.jpg';
import imageBefore10_8 from '../images/portfolio/before_after/project10/8.jpg';
import imageBefore10_9 from '../images/portfolio/before_after/project10/9.jpg';
import imageBefore10_10 from '../images/portfolio/before_after/project10/10.jpg';
import imageBefore10_11 from '../images/portfolio/before_after/project10/11.jpg';
import imageBefore10_12 from '../images/portfolio/before_after/project10/12.jpg';
import imageBefore10_13 from '../images/portfolio/before_after/project10/13.jpg';
import imageBefore10_14 from '../images/portfolio/before_after/project10/14.jpg';
import imageBefore10_15 from '../images/portfolio/before_after/project10/15.jpg';
import imageBefore10_16 from '../images/portfolio/before_after/project10/16.jpg';
import imageBefore10_17 from '../images/portfolio/before_after/project10/17.jpg';
import imageBefore10_18 from '../images/portfolio/before_after/project10/18.jpg';

import classes from "../styles/pages/portfolioPage.module.scss";
import {LanguageContext} from "../App";

function PortfolioSlider() {

    const languageContext = useContext(LanguageContext);

    return(
        <>
            <Carousel variant="dark">
                <Carousel.Item interval={6000}>
                    <img src={image1} className={classes.carouselImages}/>
                </Carousel.Item>
                <Carousel.Item interval={6000}>
                    <img src={image2} className={classes.carouselImages} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={6000}>
                    <img src={image3} className={classes.carouselImages} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={10000}>
                    <img src={image4} className={classes.carouselImages} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={6000}>
                    <img src={image5} className={classes.carouselImages} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={6000}>
                    <img src={image6} className={classes.carouselImages} loading="lazy"/>
                </Carousel.Item>
            </Carousel>

            <h2>{languageContext.portfolioPage.showerPan}</h2>
            <Carousel variant="dark">
                <Carousel.Item interval={40000}>
                    <img src={imageShowerpan1} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageShowerpan2} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageShowerpan3} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageShowerpan4} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageShowerpan5} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
            </Carousel>
            <h2>{languageContext.portfolioPage.remodeling}</h2>
            <Carousel variant="dark">
                <Carousel.Item interval={40000}>
                    <img src={imageRemodeling1} className={classes.carouselImages} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageRemodeling2} className={classes.carouselImages} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageRemodeling3} className={classes.carouselImages} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageRemodeling4} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageRemodeling5} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
            </Carousel>
            <h2>{languageContext.portfolioPage.backsplash}</h2>
            <Carousel variant="dark">
                <Carousel.Item interval={40000}>
                    <img src={imageBacksplash1} className={classes.carouselImages} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBacksplash2} className={classes.carouselImages} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBacksplash3} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBacksplash4} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
            </Carousel>
            <h2>{languageContext.portfolioPage.ladder}</h2>
            <Carousel variant="dark">
                <Carousel.Item interval={40000}>
                    <img src={imageLadder1} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageLadder2} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageLadder3} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageLadder4} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageLadder5} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageLadder6} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageLadder7} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageLadder8} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageLadder9} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
            </Carousel>
            <h2>{languageContext.portfolioPage.laminate}</h2>
            <Carousel variant="dark">
                <Carousel.Item interval={40000}>
                    <img src={laminate1} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={laminate2} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={laminate3} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={laminate4} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
            </Carousel>

            <h2>{languageContext.portfolioPage.projects}</h2>
            <Carousel variant="dark">
                <Carousel.Item interval={40000}>
                    <img src={imageProject1_1} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageProject1_2} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageProject1_3} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageProject1_4} className={classes.carouselImages} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageProject1_5} className={classes.carouselImages} loading="lazy"/>
                </Carousel.Item>
            </Carousel>
            <br/>
            <Carousel variant="dark">
                <Carousel.Item interval={40000}>
                    <img src={imageProject2_1} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageProject2_2} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageProject2_3} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageProject2_4} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageProject2_5} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageProject2_6} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageProject2_7} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageProject2_8} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageProject2_9} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageProject2_10} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageProject2_11} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageProject2_12} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageProject2_13} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
            </Carousel>
            <br/>
            <Carousel variant="dark">
                <Carousel.Item interval={40000}>
                    <img src={imageProject3_1} className={classes.carouselImages} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageProject3_2} className={classes.carouselImages} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageProject3_3} className={classes.carouselImages} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageProject3_4} className={classes.carouselImages} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageProject3_5} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
            </Carousel>

            <h2>{languageContext.portfolioPage.beforeAfter}</h2>
            <Carousel variant="dark">
                <Carousel.Item interval={40000}>
                    <img src={imageBefore1_1} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore1_2} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore1_3} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore1_4} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore1_5} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore1_6} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore1_7} className={classes.carouselImages} loading="lazy"/>
                </Carousel.Item>
            </Carousel>
            <br/>
            <Carousel variant="dark">
                <Carousel.Item interval={40000}>
                    <img src={imageBefore2_1} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore2_2} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore2_3} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore2_4} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore2_5} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
            </Carousel>
            <br/>
            <Carousel variant="dark">
                <Carousel.Item interval={40000}>
                    <img src={imageBefore3_1} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore3_2} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore3_3} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore3_4} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore3_5} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore3_6} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore3_7} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore3_8} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore3_9} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore3_10} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore3_11} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore3_12} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore3_13} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore3_14} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore3_15} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
            </Carousel>
            <br/>
            <Carousel variant="dark">
                <Carousel.Item interval={40000}>
                    <img src={imageBefore4_1} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore4_2} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore4_3} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore4_4} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore4_5} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore4_6} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore4_7} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore4_8} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore4_9} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
            </Carousel>
            <br/>
            <Carousel variant="dark">
                <Carousel.Item interval={40000}>
                    <img src={imageBefore5_1} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore5_2} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore5_3} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore5_4} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore5_5} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore5_6} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore5_7} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore5_8} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore5_9} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore5_10} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore5_11} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore5_12} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore5_13} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
            </Carousel>
            <br/>
            <Carousel variant="dark">
                <Carousel.Item interval={40000}>
                    <img src={imageBefore6_1} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore6_2} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore6_3} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore6_4} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore6_5} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore6_6} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore6_7} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore6_8} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore6_9} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore6_10} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore6_11} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore6_12} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore6_13} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore6_14} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore6_15} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
            </Carousel>
            <br/>
            <Carousel variant="dark">
                <Carousel.Item interval={40000}>
                    <img src={imageBefore7_1} className={classes.carouselImages} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore7_2} className={classes.carouselImages} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore7_3} className={classes.carouselImages} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore7_4} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore7_5} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore7_6} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore7_7} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore7_8} className={classes.carouselImages} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore7_9} className={classes.carouselImages} loading="lazy"/>
                </Carousel.Item>
            </Carousel>
            <br/>
            <Carousel variant="dark">
                <Carousel.Item interval={40000}>
                    <img src={imageBefore8_1} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore8_2} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore8_3} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore8_4} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore8_5} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore8_6} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore8_7} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore8_8} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore8_9} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore8_10} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore8_11} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore8_12} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore8_13} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore8_14} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore8_15} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore8_16} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore8_17} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore8_18} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore8_19} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore8_20} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore8_21} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore8_22} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore8_23} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore8_24} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore8_25} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
            </Carousel>
            <br/>
            <Carousel variant="dark">
                <Carousel.Item interval={40000}>
                    <img src={imageBefore9_1} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore9_2} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore9_3} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore9_4} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore9_5} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
            </Carousel>
            <br/>
            <Carousel variant="dark">
                <Carousel.Item interval={40000}>
                    <img src={imageBefore10_1} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore10_2} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore10_3} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore10_4} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore10_5} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore10_6} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore10_7} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore10_8} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore10_9} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore10_10} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore10_11} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore10_12} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore10_13} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore10_14} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore10_15} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore10_16} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore10_17} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
                <Carousel.Item interval={40000}>
                    <img src={imageBefore10_18} className={classes.carouselImagesVertical} loading="lazy"/>
                </Carousel.Item>
            </Carousel>
        </>
    );
}

export default PortfolioSlider;