import React, {useContext} from 'react';
import {LanguageContext} from "../App";
import classes from "../styles/pages/homePage.module.scss";
import elements from "../styles/settings/elements.module.scss";
import repair from '../images/repair_blue.png';
import people from '../images/people_blue.png';
import price from '../images/price_blue.png';
import time from '../images/time_blue.png';
import quality from '../images/quality_blue.png';
import result from '../images/result_blue.png';
import certificate from '../images/certificate.png';
import {Col, Container, Row} from "react-bootstrap";
import HomeSlider from "../components/HomeSlider";
import ContactUs from "../components/ContactUs";
import {BsDoorOpenFill, BsWindows} from "react-icons/bs";
import {GiFlatPlatform} from "react-icons/gi";
import {FaPaintRoller, FaPencilRuler, FaShower} from "react-icons/fa";
import {IoIosWater, IoMdImages} from "react-icons/io";
import {GiBrickWall} from "react-icons/gi";
import {NavLink} from "react-router-dom";
import {paths} from "../paths";
import Reviews from "../components/Reviews";
import Quote from "../components/Quote";
import {Helmet} from "react-helmet";
import Certificate from "../components/Certificate";

function HomePage() {

    const languageContext = useContext(LanguageContext);

    return(
        <>

            <div className={classes.start_image}>
                <div className={classes.block}></div>
                <h1 className={classes.start_message}>{languageContext.homePage.startMessage1}<br/>{languageContext.homePage.startMessage2}</h1>
                <a href="#contact" className={classes.start_contact}>{languageContext.homePage.contact}</a>
            </div>

            <ContactUs/>

            <Container className={classes.skills_layout}>
                <Row>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={6}>
                        <img src={repair} alt={"Experience"} className={classes.skills}/>
                        <b className={classes.skillsTitle}>{languageContext.homePage.experience}</b>
                        <p>{languageContext.homePage.experienceDescription}</p>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={6}>
                        <img src={quality} alt={"Quality"} className={classes.skills}/>
                        <b className={classes.skillsTitle}>{languageContext.homePage.quality}</b>
                        <p>{languageContext.homePage.qualityDescription}</p>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={6}>
                        <img src={price} alt={"Price"} className={classes.skills}/>
                        <b className={classes.skillsTitle}>{languageContext.homePage.price}</b>
                        <p>{languageContext.homePage.priceDescription}</p>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={6}>
                        <img src={people} alt={"Client"} className={classes.skills}/>
                        <b className={classes.skillsTitle}>{languageContext.homePage.clients}</b>
                        <p>{languageContext.homePage.clientsDescription}</p>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={6}>
                        <img src={result} alt={"Result"} className={classes.skills}/>
                        <b className={classes.skillsTitle}>{languageContext.homePage.result}</b>
                        <p>{languageContext.homePage.resultDescription}</p>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={6}>
                        <img src={time} alt={"time"} className={classes.skills}/>
                        <b className={classes.skillsTitle}>{languageContext.homePage.time}</b>
                        <p>{languageContext.homePage.timeDescription}</p>
                    </Col>
                </Row>
            </Container>

            <Quote/>

            <Container fluid className={classes.popularServices}>
                <Row>
                    <Col>
                        <h2 className={classes.popularServicesTitle}>{languageContext.homePage.mostPopularServices}</h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <NavLink to={paths.services + paths.doors}>
                            <BsDoorOpenFill className={classes.servicesIcon}/>
                            <p className={classes.servicesText}>{languageContext.homePage.doors}</p>
                        </NavLink>
                    </Col>
                    <Col>
                        <NavLink to={paths.services + paths.windows}>
                            <BsWindows className={classes.servicesIcon}/>
                            <p className={classes.servicesText}>{languageContext.homePage.windows}</p>
                        </NavLink>
                    </Col>
                    <Col>
                        <NavLink to={paths.services + paths.flooring}>
                            <GiFlatPlatform className={classes.servicesIcon}/>
                            <p className={classes.servicesText}>{languageContext.homePage.flooring}</p>
                        </NavLink>
                    </Col>
                    <Col>
                        <NavLink to={paths.services + paths.showerPan}>
                            <FaShower className={classes.servicesIcon}/>
                            <p className={classes.servicesText}>{languageContext.homePage.showerPan}</p>
                        </NavLink>
                    </Col>
                    <Col>
                        <NavLink to={paths.services + paths.painting}>
                            <FaPaintRoller className={classes.servicesIcon}/>
                            <p className={classes.servicesText}>{languageContext.homePage.painting}</p>
                        </NavLink>
                    </Col>
                    <Col>
                        <NavLink to={paths.services + paths.sink}>
                            <IoIosWater className={classes.servicesIcon}/>
                            <p className={classes.servicesText}>{languageContext.homePage.sink}</p>
                        </NavLink>
                    </Col>
                    <Col>
                        <NavLink to={paths.services + paths.drywall}>
                            <GiBrickWall className={classes.servicesIcon}/>
                            <p className={classes.servicesText}>{languageContext.homePage.drywall}</p>
                        </NavLink>
                    </Col>
                    <Col>
                        <NavLink to={paths.services + paths.carpentry}>
                            <FaPencilRuler className={classes.servicesIcon}/>
                            <p className={classes.servicesText}>{languageContext.homePage.carpentry}</p>
                        </NavLink>
                    </Col>
                </Row>
            </Container>

            <Container>
                <HomeSlider/>
                <div className={classes.viewMore}>
                    <NavLink to={paths.portfolio} className={elements.blue_button}>{languageContext.homePage.viewMore} <IoMdImages className={elements.buttonIcon}/></NavLink>
                </div>
            </Container>

            <Certificate/>

            <Reviews/>

            <h5 className={classes.textBetter}>{languageContext.commonText.textBetter}</h5>
        </>
    );
}

export default HomePage;