export const languages = {
    en: {
        header: {
            home: "Home",
            services: "Services",
            portfolio: "Portfolio",
            about: "About"
        },
        homePage: {
            startMessage1: "Entrust your home",
            startMessage2: "to professionals",
            contact: "Contact",
            experience: "Experience",
            experienceDescription: "Over 20 years of experience in remodeling",
            quality: "Quality",
            qualityDescription: "Improving quality to the perfect",
            price: "Price",
            priceDescription: "Competitive prices in the market",
            clients: "Clients",
            clientsDescription: "More then 200 happy clients and companies",
            result: "Result",
            resultDescription: "Make your dreams come true",
            time: "Time",
            timeDescription: "Do the job as soon as possible",
            mostPopularServices: "Most Popular Services",
            doors: "Doors",
            windows: "Windows",
            flooring: "Flooring",
            showerPan: "Shower Pan",
            painting: "Painting",
            sink: "Sink",
            drywall: "Drywall",
            carpentry: "Carpentry",
            viewMore: "View More"
        },
        reviews: {
            title: "What Our Customers Say",
            list: [
                {name: "Michael S.", stars: 5, description: "Alex did an awesome job remodeling my kitchen. The work is just so well done, and it looks incredible. I appreciate the attention to detail and the effort put in. If you want a great remodeling experience, this handyman is your best choice.", image: "review1.png"},
                {name: "Anna R.", stars: 5, description: "Working with this team on our home remodel was a breeze. They had some really cool ideas and made our place look amazing. The team was professional, and the whole process was stress-free. I can't thank Alex enough for making our home so awesome. If you want someone who's creative, skilled, and cares about doing a great job, definitely choose him.", image: "review2.png"},
                {name: "Alex P.", stars: 5, description: "Getting Alex for our home remodel was the best decision. The place looks totally different now, in a really good way. He was great to work with, and I'm really happy with the outcome.", image: "review3.png"},
                {name: "Patrick B.", stars: 5, description: "I hired handymen for a remodel, and it was a great experience. They were total pros and paid attention to every little thing. The project was finished on time, and the end result was top-notch. If you're looking for someone who's really good at what they do, I highly recommend them.", image: "review4.png"},
                {name: "Andrew C.", stars: 5, description: "I recently got Alex to do some remodeling at my place, and I'm over the moon with how it turned out. From start to finish, he was awesome to work with. The attention to detail and the way he went above and beyond really stood out. The final result was way better than I expected. If you're looking for someone who cares about his work and does a fantastic job, go with Alex.", image: "review5.png"},
            ],
            starDescription: "click a star to evaluate the work"
        },
        commonText: {
            title: "About Me",
            aboutMe: "Welcome to my website! My name is Alexander and I am a professional home remodeler. I have more than 20 years of experience in this field, and I am ready to offer you quality and reliable services at affordable prices.\n" +
                "I carry out various types of home renovation and decoration work, work with high-quality materials and tools, comply with deadlines and guarantees, and take into account all the wishes and needs of clients. I am always ready to advise you on any issues related to home renovation and offer the best solutions for your budget and taste.\n" +
                "If you are looking for a reliable and experienced home remodeler, then you have come to the right place! Contact me using an application on the website or directly via sms/email, and I will be happy to come to you for a free inspection and assessment of the work. I am waiting for you!",
            textBetter: "Please use text messages only. This way we keep in touch with you better, keep our agreements, don't forget anything and make our service better."
        },
        contactUs: {
            title: "Contact Us",
            appointment: "SCHEDULE APPOINTMENT",
            estimate: "GET AN ESTIMATE",
            namePlaceholder: "Your Name",
            phonePlaceholder: "Phone Number",
            descriptionPlaceholder: "Tell me about your project",
            reviewTitle: "Write Your Review",
            reviewDescriptionPlaceholder: "Your Review",
            emailPlaceholder: "Email",
            upload: "Upload photo",
            submit: "Submit"
        },
        footer: {
            area: "Charlotte NC",
            sms: "SMS",
            email: "Email",
            developer: "Developer"
        },
        aboutPage: {
            title: "About",
            name: "Alex",
            location: "Charlotte NC",
            areas: ["Southend", "Pineville", "Downtown", "Uptown", "Belmont", "Montclair South", "Lake Wylie", "Steel Creek", "Mountain Island", "Woodford Green", "Myers Park", "Collingwood", "Fort Mill", "Gastonia"]
        },
        servicesPage: {
            title: "Services",
            commonServices: "Common Services",
            residentialRemodeling: "Residential Remodeling",
            doors: "Doors",
            windows: "Windows",
            flooring: "Flooring",
            painting: "Painting",
            carpentry: "Carpentry",
            drywall: "Drywall",
            homeImprovement: "Home Improvement",
            bathroom: "Bathroom",
            bathroomRemodeling: "Bathroom Remodeling",
            showerPan: "Shower Pan",
            waterDamageRestoration: "Water Damage Restoration",
            kitchen: "Kitchen",
            kitchenRemodeling: "Kitchen Remodeling",
            sink: "Sink",
            countertopInstallation: "Countertop Installation",
            wallpaper: "Wallpaper"
        },
        portfolioPage: {
            title: "My Portfolio",
            showerPan: "Shower Pan",
            remodeling: "Remodeling",
            backsplash: "Backsplash",
            ladder: "Ladder",
            laminate: "Laminate",
            projects: "Projects",
            beforeAfter: "Before & After"
        },
        services: {
            BathroomRemodelingPage: {
                title: "Bathroom Remodeling",
                description: "Create a Spa-Like Oasis with a Luxurious Bathroom Remodeling",
                text1: "Your bathroom is more than just a functional space; it's a sanctuary where you start and end your day. We specialize in bathroom remodeling services that transform this essential room into a luxurious oasis of relaxation and functionality. Whether you dream of a spa-like retreat or simply want to update the look and feel of your bathroom, we're here to make it happen.",
                text2: "Our Approach",
                text3: "Our approach to bathroom remodeling is rooted in your vision and our expertise. We work closely with you to understand your preferences, needs, and budget, ensuring that every aspect of your bathroom makeover aligns with your unique style and lifestyle. From concept to completion, our dedicated team of experts is with you every step of the way.",
                text4: "Enhanced Aesthetics",
                text5: "Revitalize your bathroom's aesthetics with our remodeling services. We offer a wide range of design options, from contemporary minimalism to timeless elegance. Our attention to detail ensures that every element, from tile selection to lighting fixtures, contributes to the overall ambiance you desire.",
                text6: "Improved Functionality",
                text7: "A well-designed bathroom should not only look beautiful but also function seamlessly. We optimize the layout and choose fixtures and materials that enhance convenience and efficiency. Whether it's space-saving solutions, smart storage, or accessible features, we make sure your bathroom works for you.",
                text8: "Luxurious Upgrades",
                text9: "Elevate your bathroom with luxurious upgrades. From spa-inspired showers and soaking tubs to radiant floor heating and high-end finishes, we bring a touch of opulence to your daily routine.",
                text10: "Customization",
                text11: "Your bathroom is as unique as you are, and we embrace the opportunity to create a custom solution that matches your specific requirements. Whether it's a unique vanity design, bespoke cabinetry, or personalized tile patterns, we tailor your bathroom to your taste."
            },
            CarpentryPage: {
                title: "Carpentry",
                description: "Craft Custom Carpentry Solutions for a Personalized Touch",
                text1: "Carpentry is an art, a skill, and a craft that adds warmth, functionality, and character to any space. We specialize in expert carpentry services that transform your ideas into beautifully crafted, custom woodwork. From intricate details to structural enhancements, our dedicated team of craftsmen brings your visions to life.",
                text2: "Custom Carpentry",
                text3: "Our custom carpentry services cater to your unique needs and preferences. Whether you're looking for custom cabinetry, built-in shelving, intricate molding, or a one-of-a-kind piece of furniture, we have the expertise to create it. We work closely with you to design and build woodwork that perfectly complements your space and style.",
                text4: "Structural Enhancements",
                text5: "Carpentry isn't just about aesthetics; it's also about structural integrity. Our team can reinforce or repair existing structures or create new ones, such as decks, pergolas, or even home additions. Our commitment to quality ensures that every project we undertake is not only beautiful but also built to last.",
                text6: "Restoration and Repairs",
                text7: "Preserving the beauty of older homes or restoring damaged woodwork is a passion of ours. We have the skills and knowledge to repair and restore wood elements to their former glory, whether it's a historic home, antique furniture, or weathered outdoor structures.",
                text8: "Meticulous Detailing",
                text9: "The beauty of carpentry lies in the details. Our craftsmen pay meticulous attention to every aspect of the project, from precise measurements to the selection of the finest wood species. We ensure that every joint, curve, and finish is executed flawlessly."
            },
            CountertopInstallationPage: {
                title: "Countertop Installation",
                description: "Choose the Perfect Countertop for Your Kitchen's New Look",
                text1: "Countertops are the focal point of your kitchen and bathroom, setting the tone for the entire space. We specialize in countertop installation services that not only enhance the functionality of your spaces but also add style and sophistication. Whether you're dreaming of a modern culinary haven or a spa-like bathroom retreat, we're here to bring your vision to life.",
                text2: "Our Approach",
                text3: "Our approach to countertop installation is rooted in craftsmanship and customization. We work closely with you to understand your design preferences, budget, and lifestyle needs, ensuring that every aspect of your project aligns with your unique vision. From material selection to finishing touches, our dedicated team of experts is with you every step of the way.",
                text4: "Kitchen Countertops",
                text5: "Revitalize your kitchen's aesthetics and functionality with our countertop installation services. We offer a wide range of materials, including granite, quartz, marble, and butcher block, to match your style. Our precise measurements and expert installation ensure a perfect fit that complements your cabinetry and appliances.",
                text6: "Bathroom Countertops",
                text7: "Upgrade your bathroom with elegant and durable countertops. We provide options such as natural stone, engineered quartz, and solid surface materials that not only add beauty but also withstand the demands of daily use. From single vanities to luxurious double sinks, we tailor the design to your needs.",
                text8: "Customization",
                text9: "Your countertops should reflect your personality and lifestyle. We offer custom countertop solutions, allowing you to choose the color, texture, edge profile, and finish that best suits your preferences. Whether you prefer a classic, timeless look or a modern, sleek design, we can accommodate your vision.",
                text10: "Repairs and Maintenance",
                text11: "If you're experiencing issues with your existing countertops, we provide repair and maintenance services to restore their beauty and functionality. From minor repairs to resealing, we ensure that your countertops look and perform like new."
            },
            DoorsPage: {
                title: "Doors Installation",
                description: "Enhance Your Home's Curb Appeal with Stunning New Doors",
                text1: "Doors are the gateways to your home, providing security, style, and functionality. We specialize in door installation and upgrades, offering solutions that not only enhance the aesthetics of your property but also improve security and energy efficiency.",
                text2: "Installation Services",
                text3: "Our door installation services are all about elevating your home's curb appeal and interior design. Whether you're seeking to replace an outdated front door, install a new patio door, or upgrade interior doors, we have the expertise to make it happen. Our vast selection of door styles, materials, and finishes ensures you'll find the perfect fit for your unique taste.",
                text4: "Security and Safety",
                text5: "Security is paramount, and our doors are designed with your safety in mind. We offer a range of sturdy and secure options, including reinforced entry doors with advanced locking systems. Your peace of mind is our top priority.",
                text6: "Energy Efficiency",
                text7: "Doors play a crucial role in your home's energy efficiency. Our energy-efficient door solutions are engineered to keep your home comfortable while reducing energy costs. We provide options like insulated doors with weatherstripping that seals out drafts, making your space cozy year-round.",
                text8: "Customization",
                text9: "Your home should reflect your personality and style. That's why we offer custom door solutions, allowing you to choose the size, design, color, and hardware that best suits your taste and complements your home's architecture."
            },
            DrywallPage: {
                title: "Drywall",
                description: "Repair and Renew Your Walls with Expert Drywall Services",
                text1: "Drywall, often referred to as gypsum board or sheetrock, is the hidden hero behind the walls of your home or commercial space. We specialize in professional drywall services that provide the essential foundation for your walls and ceilings. Our skilled team ensures that your spaces are not only structurally sound but also aesthetically flawless.",
                text2: "Installation Services",
                text3: "Our drywall installation services are the first step in creating a smooth and uniform surface for your walls and ceilings. Whether you're constructing a new building, remodeling, or need repairs, our experts have the knowledge and experience to handle projects of all sizes. We use high-quality drywall materials, ensuring durability and longevity.",
                text4: "Seamless Finishing",
                text5: "The key to a flawless interior finish lies in expert drywall finishing. Our team pays meticulous attention to detail, ensuring that seams are perfectly concealed, surfaces are smooth, and corners are sharp. Our finishing touches create a seamless canvas for your paint or wallpaper, enhancing the overall aesthetics of your space.",
                text6: "Repairs and Patching",
                text7: "From minor dents and dings to more extensive damage, we provide drywall repair and patching services to restore the integrity of your walls and ceilings. Whether it's water damage, holes, or cracks, we have the expertise to make your surfaces look like new.",
                text8: "Customization",
                text9: "We understand that each project is unique, and we offer custom drywall solutions to meet your specific needs. Whether you require specialty textures, soundproofing, or fire-resistant drywall, we can tailor our services to match your requirements."
            },
            FlooringPage: {
                title: "Flooring",
                description: "Transform Your Space with Beautiful New Flooring Options",
                text1: "Flooring is the foundation of your home's interior, setting the stage for its overall aesthetics, comfort, and functionality. We specialize in flooring installation and transformation services that breathe new life into your living spaces, creating an ambiance that reflects your style and enhances your everyday life.",
                text2: "Installation Services",
                text3: "Our flooring installation services are designed to elevate your home's interior. Whether you're looking to replace worn-out flooring, update your style, or increase the value of your property, our expert team is here to guide you. We offer a diverse range of flooring materials, including hardwood, laminate, tile, vinyl, and carpet, ensuring you find the perfect match for your vision.",
                text4: "Aesthetic Enhancement",
                text5: "Your flooring choice plays a pivotal role in the overall aesthetic of your home. Our flooring solutions are crafted to not only look beautiful but also withstand the test of time. From classic elegance to contemporary chic, we have the perfect flooring options to complement your unique style.",
                text6: "Functionality and Durability",
                text7: "Flooring is more than just a surface—it's where you live, play, and make memories. Our flooring materials are selected for their durability and ease of maintenance, ensuring your floors remain beautiful and functional for years to come.",
                text8: "Custom Design",
                text9: "We understand that every home is unique, and your flooring should be too. That's why we offer custom design solutions, allowing you to choose the patterns, colors, and textures that best suit your preferences and lifestyle."
            },
            HomeImprovementPage: {
                title: "Home Improvement",
                description: "Elevate Your Home's Overall Value Through Expert Home Improvement",
                text1: "Your home is more than just a structure; it's a reflection of your personality, a sanctuary for your family, and an investment in your future. In our company, we specialize in home improvement services that enhance the beauty, comfort, and functionality of your living spaces. Whether you're looking to update a single room or embark on a complete home makeover, we're here to turn your vision into reality.",
                text2: "Our Approach",
                text3: "Our approach to home improvement is centered on collaboration and craftsmanship. We work closely with you to understand your goals, preferences, and budget, ensuring that every aspect of your project is tailored to your unique needs. From concept to completion, our dedicated team of experts is with you every step of the way.",
                text4: "Interior Upgrades",
                text5: "Revitalize the heart of your home with our interior improvement services. Whether it's a modern kitchen remodel, a luxurious bathroom renovation, or creating a functional and stylish living space, we have the expertise to bring your ideas to life. Our attention to detail ensures that every corner of your interior is beautifully finished.",
                text6: "Exterior Enhancements",
                text7: "Boost your home's curb appeal and functionality with our exterior improvement solutions. From siding replacement to energy-efficient window installations, we enhance the structural integrity and energy efficiency of your property while making it more visually appealing.",
                text8: "Renovation and Remodeling",
                text9: "Renovating or remodeling your home is a significant undertaking, and we're here to make the process seamless. Our team of skilled craftsmen, designers, and project managers is dedicated to delivering exceptional results within your timeline and budget constraints.",
                text10: "Customization",
                text11: "Every home is unique, and we embrace the opportunity to create custom solutions that match your specific requirements. Whether it's custom cabinetry, built-in shelving, or personalized landscaping, we turn your dreams into reality with meticulous attention to detail."
            },
            KitchenRemodelingPage: {
                title: "Kitchen Renovation",
                description: "Cook up Your Dream Kitchen with a Complete Renovation",
                text1: "Your kitchen is the heart of your home, where cherished memories are made and delicious meals are prepared. We specialize in kitchen renovation services that breathe new life into this vital space. Whether you dream of a modern culinary haven, a cozy family gathering place, or simply want to update the look and feel of your kitchen, we're here to make it happen.",
                text2: "Our Approach",
                text3: "Our approach to kitchen renovation is driven by collaboration and craftsmanship. We work closely with you to understand your goals, preferences, and budget, ensuring that every aspect of your kitchen makeover aligns with your unique style and lifestyle. From design to completion, our dedicated team of experts is with you every step of the way.",
                text4: "Enhanced Aesthetics",
                text5: "Revitalize your kitchen's aesthetics with our renovation services. We offer a wide range of design options, from contemporary minimalism to timeless elegance. Our attention to detail ensures that every element, from cabinetry to countertops, contributes to the overall ambiance you desire.",
                text6: "Improved Functionality",
                text7: "A well-designed kitchen should not only look beautiful but also function seamlessly. We optimize the layout and choose fixtures and materials that enhance convenience and efficiency. Whether it's space-saving solutions, smart storage, or modern appliances, we make sure your kitchen works for you.",
                text8: "Luxurious Upgrades",
                text9: "Elevate your kitchen with luxurious upgrades. From chef-inspired appliances and custom cabinetry to stunning backsplashes and high-end finishes, we bring a touch of opulence to your culinary space.",
                text10: "Customization",
                text11: "Your kitchen is a reflection of your personality and needs. We offer custom kitchen renovation solutions, allowing you to choose the size, layout, and material that best suits your preferences. Whether you prefer a classic farmhouse style or a sleek, modern look, we can bring your vision to life."
            },
            PaintingPage: {
                title: "Painting",
                description: "Add a Fresh Coat of Paint to Breathe Life into Your Rooms",
                text1: "Painting is one of the most powerful and cost-effective ways to transform any space. We specialize in professional painting services that breathe new life into your home or commercial property. Our expert team combines creativity, precision, and top-quality materials to deliver stunning and long-lasting results.",
                text2: "Interior Painting",
                text3: "Our interior painting services are designed to create a fresh, inviting atmosphere within your home. Whether you're looking to update a single room, revitalize your entire interior, or simply add a splash of color, we have the expertise to make it happen. We work with you to choose the perfect color palette that reflects your style and complements your decor.",
                text4: "Exterior Painting",
                text5: "The exterior of your property is the first impression visitors and passersby have of your space. Our exterior painting services not only enhance curb appeal but also protect your property from the elements. We use high-quality paints and finishes that stand up to the toughest weather conditions, ensuring your property looks beautiful for years to come.",
                text6: "Commercial Painting",
                text7: "For businesses, a well-maintained and professionally painted interior and exterior can make a significant impact on customers and employees alike. Our commercial painting services are tailored to meet the unique needs of your business. Whether it's an office space, retail store, or industrial facility, we work efficiently to minimize disruption to your operations.",
                text8: "Attention to Detail",
                text9: "We take pride in our attention to detail and commitment to quality craftsmanship. Our skilled painters prepare surfaces meticulously, ensuring a smooth and flawless finish. We also address any repairs or surface imperfections before applying the final coats of paint, leaving you with a professional and polished result."
            },
            ResidentialRemodelingPage: {
                title: "Residential Renovation",
                description: "Revitalize Your Home with Expert Renovation Services",
                text1: "Is your home in need of a fresh, inspiring transformation? Look no further than our residential renovation services. In our company, we're passionate about breathing new life into your living spaces, making them more beautiful, functional, and valuable than ever before.",
                text2: "Our Approach",
                text3: "Our residential renovation approach is simple yet effective: we turn your vision into reality. Whether you're envisioning a complete home makeover or a targeted transformation of a specific area, we're here to bring your ideas to life.",
                text4: "Quality Transformation",
                text5: "Our commitment to quality shines through in every renovation project. We carefully select materials, employ expert craftsmanship, and embrace innovative design trends to ensure your space not only looks amazing but also stands the test of time.",
                text6: "Enhanced Living",
                text7: "Beyond aesthetics, our residential renovation services are designed to enhance the way you live. We reconfigure spaces to optimize functionality, boost energy efficiency, and adapt your home to your evolving lifestyle.",
                text8: "Tailored to You",
                text9: "Your dream space is our priority. Whether you come with a clear vision or need assistance in defining it, we're here to guide you. Our aim is to make your renovation experience seamless and enjoyable."
            },
            ShowerpanPage: {
                title: "Shower Pan Installation",
                description: "Install a Durable Shower Pan for a Leak-Free Bathing Experience",
                text1: "A well-installed shower pan is the unsung hero of your bathroom, ensuring that your daily ritual remains enjoyable and leak-free. We specialize in shower pan installation and waterproofing services that provide the essential foundation for your shower, protecting your home from water damage and creating a durable and beautiful space.",
                text2: "Shower Pan Installation",
                text3: "Our shower pan installation services are all about creating a solid and waterproof base for your shower. Whether you're building a new shower or replacing an old one, our experienced team will expertly install the shower pan to ensure proper drainage and structural integrity. We use high-quality materials, such as fiberglass, acrylic, or tile, to meet your design preferences.",
                text4: "Waterproofing Expertise",
                text5: "Waterproofing is crucial to prevent leaks and water damage. Our experts are well-versed in the latest waterproofing techniques and materials, ensuring that your shower remains dry and your walls and floors are protected. We pay meticulous attention to seams, corners, and joints to guarantee a watertight seal.",
                text6: "Custom Design",
                text7: "Your shower should reflect your style and needs. We offer custom shower pan solutions, allowing you to choose the size, shape, and material that best suits your preferences. Whether you prefer a sleek, modern look or a traditional tile design, we can bring your vision to life.",
                text8: "Repairs and Upgrades",
                text9: "If you're experiencing leaks or water damage in your existing shower, our repair and upgrade services can help. We can assess the condition of your shower pan, repair any damage, and improve the waterproofing to ensure the longevity of your shower."
            },
            SinkPage: {
                title: "Sink Installation",
                description: "Upgrade Your Kitchen with a Stylish New Sink",
                text1: "The sink is the unsung hero of your kitchen, a workhorse that plays a central role in your daily culinary activities. We specialize in sink installation and upgrade services, ensuring that this vital fixture not only meets your functional needs but also complements the overall aesthetics of your kitchen.",
                text2: "Installation Services",
                text3: "Our sink installation services are all about providing a solid foundation for your kitchen's functionality. Whether you're building a new kitchen or replacing an outdated sink, our experienced team ensures that the installation is seamless. We offer a wide range of sink styles, materials, and configurations to match your design preferences.",
                text4: "Enhanced Functionality",
                text5: "Your sink should not only look great but also work efficiently. We optimize the placement and layout of your sink to make dishwashing, food preparation, and cleanup a breeze. From single-basin to double-basin sinks, we tailor the configuration to your specific needs.",
                text6: "Luxurious Upgrades",
                text7: "Upgrade your kitchen with a high-end sink that adds a touch of luxury to your culinary space. We offer options such as farmhouse sinks, undermount sinks, and sleek stainless steel or granite composite models. Our sink upgrades enhance both the functionality and aesthetics of your kitchen.",
                text8: "Repairs and Maintenance",
                text9: "If you're experiencing issues with your existing sink, we provide sink repair and maintenance services to restore its functionality. Whether it's a leaky faucet, a damaged basin, or a clogged drain, we can diagnose and fix the problem, ensuring that your sink works like new.",
                text10: "Customization",
                text11: "We understand that each kitchen is unique, and your sink should reflect your personal style. We offer custom sink solutions, allowing you to choose the size, shape, and material that best suits your preferences. Whether you prefer a classic porcelain sink or a sleek, modern stainless steel model, we can accommodate your vision."
            },
            WaterDamageRestorationPage: {
                title: "Water Damage Restoration",
                description: "Restore Your Home After Water Damage with our Restoration Services",
                text1: "A well-installed shower pan is the unsung hero of your bathroom, ensuring that your daily ritual remains enjoyable and leak-free. We specialize in shower pan installation and waterproofing services that provide the essential foundation for your shower, protecting your home from water damage and creating a durable and beautiful space.",
                text2: "Shower Pan Installation",
                text3: "Our shower pan installation services are all about creating a solid and waterproof base for your shower. Whether you're building a new shower or replacing an old one, our experienced team will expertly install the shower pan to ensure proper drainage and structural integrity. We use high-quality materials, such as fiberglass, acrylic, or tile, to meet your design preferences.",
                text4: "Waterproofing Expertise",
                text5: "Waterproofing is crucial to prevent leaks and water damage. Our experts are well-versed in the latest waterproofing techniques and materials, ensuring that your shower remains dry and your walls and floors are protected. We pay meticulous attention to seams, corners, and joints to guarantee a watertight seal.",
                text6: "Custom Design",
                text7: "Your shower should reflect your style and needs. We offer custom shower pan solutions, allowing you to choose the size, shape, and material that best suits your preferences. Whether you prefer a sleek, modern look or a traditional tile design, we can bring your vision to life.",
                text8: "Repairs and Upgrades",
                text9: "If you're experiencing leaks or water damage in your existing shower, our repair and upgrade services can help. We can assess the condition of your shower pan, repair any damage, and improve the waterproofing to ensure the longevity of your shower."
            },
            WindowsPage: {
                title: "Windows Installation",
                description: "Upgrade Your Windows for Improved Energy Efficiency",
                text1: "Windows are more than just portals to the outside world; they are essential elements of your home's aesthetics, energy efficiency, and overall comfort. We specialize in window installation and repair services that enhance your living space in more ways than one.",
                text2: "Installation Services",
                text3: "Our window installation services are designed to breathe new life into your home. Whether you're looking to replace outdated windows, increase energy efficiency, or enhance the curb appeal of your property, we've got you covered. Our expert team will assist you in selecting the perfect windows to match your style and budget.",
                text4: "Energy Efficiency",
                text5: "Did you know that windows play a significant role in your home's energy efficiency? Our energy-efficient window solutions are designed to keep your home comfortable year-round while saving you money on utility bills. We offer a range of options, from double-pane to low-emissivity glass, all aimed at reducing heat transfer and minimizing drafts.",
                text6: "Repair and Maintenance",
                text7: "Don't let damaged or malfunctioning windows compromise your home's security and comfort. Our window repair and maintenance services can address issues such as broken panes, faulty locks, and worn-out weather stripping. Timely repairs not only ensure your windows look their best but also extend their lifespan.",
                text8: "Custom Solutions",
                text9: "We understand that each home is unique, and your window needs may differ. That's why we offer custom solutions tailored to your specifications. Whether you prefer classic, modern, or specialty windows, we have the expertise to bring your vision to life."
            },
            Wallpaper: {
                title: "Wallpaper",
                description: "Transforming Walls with Style and Elegance",
                text1: "Wallpaper is a versatile and impactful way to enhance the aesthetic appeal of any room. We specialize in wallpaper installation services that bring your walls to life with patterns, textures, and colors. Whether you're looking to add a touch of sophistication to your living room, create a cozy atmosphere in your bedroom, or make a bold statement in your office, our expert team is here to help.",
                text2: "Our Approach",
                text3: "Our approach to wallpaper installation is meticulous and tailored to your unique style and preferences. We work closely with you to select the perfect wallpaper that complements your decor and meets your design vision. From preparation to the final application, our team ensures a flawless finish that transforms your space.",
                text4: "Design Selection",
                text5: "Choosing the right wallpaper is crucial to achieving the desired look and feel of your room. We offer a wide range of wallpaper designs, including classic patterns, modern geometrics, textured finishes, and vibrant murals. Our experts can help you navigate the options and find the perfect match for your space.",
                text6: "Professional Installation",
                text7: "Wallpaper installation requires precision and expertise. Our skilled installers take care of every detail, from surface preparation and measuring to cutting and aligning the wallpaper. We ensure that the patterns match seamlessly and that there are no bubbles or imperfections, giving your walls a professional and polished appearance.",
                text8: "Room Transformations",
                text9: "Wallpaper can dramatically change the ambiance of any room. Whether you're looking to create a feature wall, add depth and texture, or infuse a room with color, our installation services deliver stunning results. We work on various spaces, including living rooms, bedrooms, dining areas, offices, and more."
            }
        }
    },
    ru: {
        header: {
            home: "Главная",
            services: "Сервисы",
            portfolio: "Портфолио",
            about: "О нас"
        },
        homePage: {
            startMessage1: "Доверьте свой дом",
            startMessage2: "профессионалам",
            contact: "Связаться",
            experience: "Опыт",
            experienceDescription: "Более 20 лет опыта в ремонте домов",
            quality: "Качество",
            qualityDescription: "Улучшение качества до совершенства",
            price: "Цена",
            priceDescription: "Конкурентоспособные цены на рынке",
            clients: "Клиенты",
            clientsDescription: "Более 200 счастливых клиентов и компаний",
            result: "Результат",
            resultDescription: "Воплатите вашу мечту",
            time: "Время",
            timeDescription: "Выполним работу как можно скорее",
            mostPopularServices: "Самые Популярные Сервисы",
            doors: "Двери",
            windows: "Окна",
            flooring: "Постилка Полов",
            showerPan: "Душевой Поддон",
            painting: "Покраска",
            sink: "Раковина",
            drywall: "Гипсокартон",
            carpentry: "Плотницкие Работы",
            viewMore: "Увидеть Больше"
        },
        reviews: {
            title: "Что Говорят Наши Клиенты",
            list: [
                {name: "Михаил С.", stars: 5, description: "Алекс проделал потрясающую работу по ремонту моей кухни. Работа сделана очень хорошо и выглядит невероятно. Я ценю внимание к деталям и затраченные усилия. Если вы хотите получить отличные впечатления от ремонта, этот мастер — ваш лучший выбор.", image: "review1.png"},
                {name: "Анна Р.", stars: 5, description: "Работать с этой командой над ремонтом нашего дома было очень легко. У них было несколько действительно крутых идей, и они сделали наше место потрясающим. Команда была профессиональной, и весь процесс прошел без стресса. Я не могу отблагодарить Алекса за то, что он сделал наш дом таким замечательным. Если вам нужен человек творческий, опытный и заботящийся о том, чтобы хорошо выполнять свою работу, обязательно выберите его.", image: "review2.png"},
                {name: "Алекс П.", stars: 5, description: "Пригласить Алекса для ремонта нашего дома было лучшим решением. Теперь это место выглядит совершенно по-другому, в хорошем смысле. С ним было здорово работать, и я очень доволен результатом.", image: "review3.png"},
                {name: "Патрик Б.", stars: 5, description: "Я нанял разнорабочих для ремонта, и это был отличный опыт. Они были настоящими профессионалами и обращали внимание на каждую мелочь. Проект был выполнен в срок, а конечный результат оказался на высшем уровне. Если вы ищете кого-то, кто действительно хорош в своем деле, я очень рекомендую его.", image: "review4.png"},
                {name: "Андрей К.", stars: 5, description: "Недавно я попросил Алекса сделать ремонт у меня дома, и я на седьмом небе от счастья от того, как это получилось. От начала до конца с ним было потрясающе работать. Внимание к деталям и то, как он делал все возможное, действительно выделялись. Конечный результат оказался намного лучше, чем я ожидал. Если вы ищете человека, который заботится о своей работе и делает ее фантастически, выбирайте Алекса.", image: "review5.png"},
            ],
            starDescription: "нажмите на звезду, чтобы оценить работу"
        },
        commonText: {
            title: "Обо Мне",
            aboutMe: "Приветствую вас на моем сайте! Меня зовут Александр, и я профессиональный мастер по ремонту домов. У меня более 20 лет опыта в этой сфере, и я готов предложить вам качественные и надежные услуги по доступным ценам.\n" +
                "Я выполняю различные виды работ по ремонту и отделке домов, работаю с высококачественными материалами и инструментами, соблюдаю сроки и гарантии, учитываю все пожелания и потребности клиентов. Я всегда готов проконсультировать вас по любым вопросам, связанным с ремонтом дома, и предложить оптимальные решения для вашего бюджета и вкуса.\n" +
                "Если вы ищете надежного и опытного мастера по ремонту домов, то вы обратились по адресу! Свяжитесь со мной с помощью заявки на сайте или напрямую через sms/email, и я с радостью приеду к вам на бесплатный осмотр и оценку работ. Я жду вас!",
            textBetter: "Пожалуйста, используйте только текстовые сообщения. Так мы лучше держим с вами связь, соблюдаем договоренности, ничего не забываем и делаем наш сервис лучше."
        },
        contactUs: {
            title: "Связаться с Нами",
            appointment: "НАЗНАЧИТЬ ВСТРЕЧУ",
            estimate: "РАССЧИТАТЬ ПРОЕКТ",
            namePlaceholder: "Ваше Имя",
            phonePlaceholder: "Ваш Номер",
            descriptionPlaceholder: "Расскажите о вашем проекте",
            reviewTitle: "Напишите Ваш Отзыв",
            reviewDescriptionPlaceholder: "Ваш Отзыв",
            emailPlaceholder: "Почта",
            upload: "Загрузить",
            submit: "Отправить"
        },
        footer: {
            area: "Шарлотт СК",
            sms: "СМС",
            email: "Почта",
            developer: "Разработчик"
        },
        aboutPage: {
            title: "О Нас",
            name: "Александр",
            areas: ["Саутенд", "Пайнвилл", "Даунтаун", "Аптаун", "Бельмонт", "Монклер Юг", "Лейк Уайли", "Стил-Крик", "Маунтин-Айленд", "Вудфорд-Грин", "Майерс-Парк", "Коллингвуд", "Форт Милл", "Гастония"]
        },
        servicesPage: {
            title: "Услуги",
            commonServices: "Общие Услуги",
            residentialRenovation: "Ремонт жилых помещений",
            doors: "Двери",
            windows: "Окна",
            flooring: "Постилка Полов",
            painting: "Покраска",
            carpentry: "Плоцкие Работы",
            drywall: "Гипсокартон",
            homeImprovement: "Улучшение Дома",
            bathroom: "Ванная",
            bathroomRenovation: "Ремонт Ванной",
            showerPan: "Душевой Поддон",
            waterDamageRestoration: "Восстановление После Водных Повреждений",
            kitchen: "Кухня",
            kitchenRenovation: "Ремонт Кухни",
            sink: "Раковина",
            countertopInstallation: "Установка Cтолешниц",
        },
        portfolioPage: {
            title: "Мое Портфолио",
            showerPan: "Душевой Поддон",
            remodeling: "Ремонт",
            backsplash: "Фартук",
            ladder: "Лестница",
            laminate: "Ламинат",
            projects: "Проекты",
            beforeAfter: "До и После"
        },
        services: {
            BathroomRemodelingPage: {
                title: "Ремонт ванной комнаты",
                description: "Создайте спа-оазис с роскошным ремонтом ванной комнаты",
                text1: "Ваша ванная комната - это не просто функциональное пространство; это убежище, где вы начинаете и заканчиваете свой день. Мы специализируемся на услугах по ремонту ванной комнаты, которые превращают это важное помещение в роскошную оазис релаксации и функциональности. Независимо от того, мечтаете ли вы о спа-подобном убежище или просто хотите обновить внешний вид и ощущение вашей ванной комнаты, мы здесь, чтобы это осуществить.",
                text2: "Наш подход",
                text3: "Наш подход к ремонту ванной комнаты основан на вашем видении и нашей экспертизе. Мы тесно сотрудничаем с вами, чтобы понять ваши предпочтения, потребности и бюджет, обеспечивая соответствие каждого аспекта обновления ванной комнаты вашему уникальному стилю и образу жизни. От концепции до завершения, наша выделенная команда экспертов находится рядом с вами на каждом этапе.",
                text4: "Повышенная эстетика",
                text5: "Оживите эстетику вашей ванной комнаты с нашими услугами по ремонту. Мы предлагаем широкий выбор вариантов дизайна, от современной минималистичности до вечной элегантности. Наше внимание к деталям гарантирует, что каждый элемент, начиная с выбора плитки и заканчивая светильниками, способствует общей атмосфере, которую вы желаете.",
                text6: "Улучшенная функциональность",
                text7: "Хорошо спроектированная ванная комната должна быть не только красивой, но и безупречно функционирующей. Мы оптимизируем планировку и выбираем сантехнику и материалы, которые повышают удобство и эффективность. Независимо от того, это решения для экономии места, умное хранение или доступные особенности, мы обеспечиваем, что ваша ванная комната работает для вас.",
                text8: "Роскошные обновления",
                text9: "Повысьте уровень вашей ванной комнаты роскошными обновлениями. От душевых кабин и гидромассажных ванн до обогрева пола и высококачественных отделочных материалов, мы добавляем штрих роскоши в вашу повседневную жизнь.",
                text10: "Пользовательская настройка",
                text11: "Ваша ванная комната так уникальна, как и вы, и мы готовы воспользоваться возможностью создать индивидуальное решение, соответствующее вашим конкретным требованиям. Независимо от того, это уникальный дизайн умывальника, индивидуальная мебель или персональные узоры плитки, мы адаптируем вашу ванную комнату под ваши вкусы."
            },
            CarpentryPage: {
                title: "Плоцкие работы",
                description: "Создание индивидуальных плотничных решений для персонального стиля",
                text1: "Плотничество - это искусство, навык и ремесло, придающее тепло, функциональность и характер любому пространству. Мы специализируемся на профессиональных услугах по плотничным работам, которые превращают ваши идеи в красивую индивидуальную деревянную отделку. Наша выделенная команда мастеров приводит ваши видения к жизни, начиная с сложных деталей и заканчивая структурными усовершенствованиями.",
                text2: "Индивидуальные плоцкие работы",
                text3: "Наши услуги по индивидуальным плотничным работам удовлетворяют ваши уникальные потребности и предпочтения. Независимо от того, вы ищете индивидуальную мебель, встроенные полки, сложные детали отделки или уникальное изделие мебели, у нас есть экспертиза для его создания. Мы тесно сотрудничаем с вами, чтобы разработать и создать деревянные изделия, которые идеально дополняют ваше пространство и стиль.",
                text4: "Структурные усовершенствования",
                text5: "Плотничество - это не только вопрос эстетики, но и структурной прочности. Наша команда может укреплять или восстанавливать существующие конструкции или создавать новые, такие как палубы, беседки или даже дополнения к дому. Наше стремление к качеству гарантирует, что каждый проект, который мы предпринимаем, не только красив, но и долговечен.",
                text6: "Восстановление и ремонт",
                text7: "Сохранение красоты старых домов или восстановление поврежденных деревянных элементов - это наша страсть. У нас есть навыки и знания для восстановления деревянных элементов в прежнюю славу, будь то исторический дом, антикварная мебель или потерявшие свою первоначальную привлекательность наружные конструкции.",
                text8: "Тщательное изготовление",
                text9: "Красота плотничества заключается в деталях. Наши мастера уделяют тщательное внимание каждому аспекту проекта, начиная от точных измерений и заканчивая выбором наилучших пород дерева. Мы гарантируем, что каждое соединение, изгиб и отделка выполнены безупречно."
            },
            CountertopInstallationPage: {
                title: "Установка столешниц",
                description: "Выберите идеальную столешницу для нового облика вашей кухни",
                text1: "Столешницы - это фокусное внимание вашей кухни и ванной комнаты, задающее тон всему пространству. Мы специализируемся на услугах по установке столешниц, которые не только улучшают функциональность ваших помещений, но и добавляют стиль и изысканность. Независимо от того, вы мечтаете о современном кулинарном убежище или спа-подобном убежище в ванной комнате, мы здесь, чтобы воплотить ваше видение в жизнь.",
                text2: "Наш подход",
                text3: "Наш подход к установке столешниц основан на мастерстве и индивидуализации. Мы тесно сотрудничаем с вами, чтобы понять ваши предпочтения в дизайне, бюджет и потребности в быту, обеспечивая соответствие каждого аспекта вашего проекта вашему уникальному видению. Начиная от выбора материалов и заканчивая последними штрихами, наша выделенная команда экспертов находится рядом с вами на каждом этапе пути.",
                text4: "Кухонные столешницы",
                text5: "Оживите эстетику и функциональность вашей кухни с нашими услугами по установке столешниц. Мы предлагаем широкий выбор материалов, включая гранит, кварц, мрамор и буферный блок, чтобы соответствовать вашему стилю. Наши точные измерения и опытная установка обеспечивают идеальное соответствие вашим шкафам и бытовой технике.",
                text6: "Ванная комната столешницы",
                text7: "Обновите свою ванную комнату элегантными и прочными столешницами. Мы предоставляем варианты, такие как натуральный камень, искусственный кварц и материалы с твердой поверхностью, которые не только придают красоту, но и выдерживают повседневные нагрузки. От одиночных туалетных столов до роскошных двойных раковин, мы настраиваем дизайн под ваши потребности.",
                text8: "Индивидуализация",
                text9: "Ваши столешницы должны отражать вашу личность и образ жизни. Мы предлагаем индивидуальные решения для столешниц, позволяя вам выбирать цвет, текстуру, профиль кромки и отделку, которая наилучшим образом соответствует вашим предпочтениям. Независимо от того, вы предпочитаете классический, вечный вид или современный, современный дизайн, мы можем удовлетворить ваше видение."
            },
            DoorsPage: {
                title: "Установка Дверей",
                description: "Улучшите привлекательность вашего дома с впечатляющими новыми дверьми",
                text1: "Двери - это ворота в ваш дом, обеспечивающие безопасность, стиль и функциональность. Мы специализируемся на установке и модернизации дверей, предлагая решения, которые не только улучшают эстетику вашей собственности, но и повышают безопасность и энергоэффективность.",
                text2: "Услуги по установке",
                text3: "Наши услуги по установке дверей направлены на поднятие привлекательности вашего дома и дизайна интерьера. Независимо от того, вы хотите заменить устаревшие входные двери, установить новую патио-дверь или обновить внутренние двери, у нас есть опыт, чтобы это сделать. Наша огромный выбор стилей, материалов и отделок гарантирует, что вы найдете идеальное решение, соответствующее вашему уникальному вкусу.",
                text4: "Безопасность и безопасность",
                text5: "Безопасность является важным аспектом, и наши двери разработаны с вашей безопасностью в виду. Мы предлагаем широкий выбор прочных и надежных вариантов, включая усиленные входные двери с продвинутыми системами запирания. Ваше спокойствие - наш главный приоритет.",
                text6: "Энергоэффективность",
                text7: "Двери играют ключевую роль в энергоэффективности вашего дома. Наши энергоэффективные дверные решения разработаны для поддержания комфорта в вашем доме и снижения расходов на энергию. Мы предоставляем варианты, такие как изоляционные двери с уплотнителями, которые устраняют сквозняки и обеспечивают уютное пребывание в помещении круглый год.",
                text8: "Индивидуализация",
                text9: "Ваш дом должен отражать вашу личность и стиль. Поэтому мы предлагаем индивидуальные решения для дверей, позволяя вам выбирать размер, дизайн, цвет и фурнитуру, наилучшим образом соответствующие вашим предпочтениям и архитектуре вашего дома."
            },
            DrywallPage: {
                title: "Гипсокартон",
                description: "Восстановление и обновление ваших стен с помощью профессиональных услуг по гипсокартону",
                text1: "Гипсокартон, часто называемый гипсовой доской или штукатурной доской, является скрытым героем за стенами вашего дома или коммерческого помещения. Мы специализируемся на профессиональных услугах по гипсокартону, которые обеспечивают не только несущий фундамент для ваших стен и потолков, но и эстетически безупречными.",
                text2: "Услуги по установке",
                text3: "Наши услуги по установке гипсокартона - это первый шаг к созданию гладкой и однородной поверхности для ваших стен и потолков. Независимо от того, вы строите новое здание, ремонтируете его или нуждаетесь в реставрации поврежденных поверхностей, наши специалисты по гипсокартону сделают это с высочайшим мастерством и точностью.",
                text4: "Реставрация и обновление",
                text5: "Время и износ могут привести к повреждениям и недостаткам в ваших стенах и потолках. Мы предлагаем услуги по реставрации и обновлению гипсокартона, включая поправку трещин, сколов и других дефектов. Наши специалисты гарантируют, что ваши стены выглядят как новые.",
                text6: "Покрытие и отделка",
                text7: "Для идеального внешнего вида важно обратить внимание на покрытие и отделку гипсокартона. Мы предоставляем широкий выбор финишных покрытий, включая краску, обои и декоративные отделки, чтобы ваш интерьер выглядел завершенным и стильным.",
                text8: "Индивидуализация",
                text9: "Ваши стены и потолки могут отражать ваш стиль и предпочтения. Поэтому мы предоставляем индивидуальные решения для гипсокартона, позволяя вам выбирать текстуру, цвет и отделку, которые наилучшим образом соответствуют вашему интерьеру и вкусу."
            },
            FlooringPage: {
                title: "Напольные покрытия",
                description: "Преобразуйте ваше пространство с красивыми новыми вариантами напольных покрытий",
                text1: "Напольное покрытие - это основа интерьера вашего дома, задающая общую эстетику, комфорт и функциональность. Мы специализируемся на монтаже напольных покрытий и услугах трансформации, которые придают новизну вашему жилому пространству, создавая атмосферу, которая отражает ваш стиль и улучшает вашу повседневную жизнь.",
                text2: "Услуги по монтажу",
                text3: "Наши услуги по монтажу напольных покрытий разработаны для улучшения интерьера вашего дома. Независимо от того, хотите ли вы заменить изношенные напольные покрытия, обновить свой стиль или увеличить стоимость вашей собственности, наша экспертная команда здесь, чтобы вас руководить. Мы предлагаем разнообразный выбор материалов для напольных покрытий, включая дерево, ламинат, плитку, винил и ковролин, обеспечивая выбор идеально подходящего варианта для вашего видения.",
                text4: "Эстетическое усовершенствование",
                text5: "Ваш выбор напольного покрытия играет решающую роль в общей эстетике вашего дома. Наши решения для напольных покрытий разрабатываются так, чтобы не только выглядеть красиво, но и выдерживать испытание временем. От классической элегантности до современной моды, у нас есть идеальные варианты напольных покрытий, которые дополнят ваш уникальный стиль.",
                text6: "Функциональность и прочность",
                text7: "Напольное покрытие - это не просто поверхность, это то место, где вы живете, играете и создаете воспоминания. Наши материалы для напольных покрытий выбираются из-за их прочности и легкости ухода, обеспечивая, что ваши полы останутся красивыми и функциональными на протяжении многих лет.",
                text8: "Индивидуальный дизайн",
                text9: "Мы понимаем, что каждый дом уникален, и ваше напольное покрытие тоже должно быть таким. Поэтому мы предлагаем индивидуальные решения в дизайне, позволяя вам выбирать узоры, цвета и текстуры, которые лучше всего подходят ваших предпочтениям и образу жизни."
            },
            HomeImprovementPage: {
                title: "Улучшение дома",
                description: "Поднимите общую стоимость вашего дома с помощью профессиональных услуг по улучшению дома",
                text1: "Ваш дом - это больше, чем просто структура; он отражение вашей личности, убежище для вашей семьи и инвестиция в ваше будущее. В нашей компании мы специализируемся на услугах по улучшению дома, которые повышают красоту, комфорт и функциональность ваших жилых пространств. Независимо от того, вы хотите обновить одну комнату или начать полный ремонт дома, мы здесь, чтобы превратить ваше видение в реальность.",
                text2: "Наш подход",
                text3: "Наш подход к улучшению дома основан на сотрудничестве и мастерстве. Мы близко работаем с вами, чтобы понять ваши цели, предпочтения и бюджет, обеспечивая, что каждый аспект вашего проекта будет адаптирован к вашим уникальным потребностям. С концепции до завершения, наша специализированная команда экспертов с вами на каждом этапе пути.",
                text4: "Внутренние улучшения",
                text5: "Оживите сердце вашего дома с нашими услугами по внутреннему улучшению. Будь то современный ремонт кухни, роскошный ремонт ванной комнаты или создание функционального и стильного жилого пространства, у нас есть экспертиза для воплощения ваших идей. Наше внимание к деталям гарантирует, что каждый угол вашего интерьера будет красиво завершен.",
                text6: "Улучшения внешнего вида",
                text7: "Повысьте привлекательность вашего дома и функциональность с нашими решениями по улучшению внешних характеристик. От замены сайдинга до установки энергоэффективных окон, мы улучшаем структурную целостность и энергоэффективность вашей собственности, делая ее более привлекательной визуально.",
                text8: "Реновация и ремонт",
                text9: "Реновация или ремонт вашего дома - это серьезное мероприятие, и мы здесь, чтобы сделать этот процесс беспроблемным. Наша команда опытных ремесленников, дизайнеров и менеджеров проектов посвятила себя достижению исключительных результатов в пределах ваших сроков и бюджетных ограничений.",
                text10: "Индивидуализация",
                text11: "Каждый дом уникален, и мы ценим возможность создать индивидуальные решения, которые соответствуют вашим конкретным требованиям. Будь то заказные шкафы, встроенные полки или персонализированный ландшафтный дизайн, мы превратим ваши мечты в реальность с тщательным вниманием к деталям."
            },
            KitchenRemodelingPage: {
                title: "Реновация кухни",
                description: "Создайте свою мечтательную кухню с полной реновацией",
                text1: "Ваша кухня - сердце вашего дома, где создаются дорогие воспоминания и готовятся вкусные блюда. Мы специализируемся на услугах по реновации кухни, которые придают новую жизнь этому важному пространству. Независимо от того, мечтаете ли вы о современном кулинарном убежище, уютном семейном месте для встреч или просто хотите обновить внешний вид и ощущение вашей кухни, мы здесь, чтобы сделать это.",
                text2: "Наш подход",
                text3: "Наш подход к реновации кухни основан на сотрудничестве и мастерстве. Мы близко работаем с вами, чтобы понять ваши цели, предпочтения и бюджет, обеспечивая, что каждый аспект обновления вашей кухни соответствует вашему уникальному стилю и образу жизни. С дизайна до завершения, наша специализированная команда экспертов с вами на каждом этапе пути.",
                text4: "Повышенная эстетика",
                text5: "Оживите эстетику вашей кухни с нашими услугами по реновации. Мы предлагаем широкий спектр дизайнерских решений, от современного минимализма до вечной элегантности. Наше внимание к деталям гарантирует, что каждый элемент, от мебели до столешниц, способствует общей атмосфере, которую вы желаете.",
                text6: "Повышенная функциональность",
                text7: "Хорошо спроектированная кухня должна быть не только красивой, но и функциональной. Мы оптимизируем планировку и выбираем фурнитуру и материалы, которые повышают удобство и эффективность. Будь то решения для экономии пространства, умное хранение или современные бытовые приборы, мы гарантируем, что ваша кухня будет работать для вас.",
                text8: "Роскошные улучшения",
                text9: "Повысьте свою кухню с роскошными обновлениями. От кухонных бытовых приборов, вдохновленных поваром, до заказной мебели и красивых сплеш-панелей и высококачественных отделок, мы придаем кулинарному пространству штрих роскоши.",
                text10: "Индивидуализация",
                text11: "Ваша кухня - это отражение вашей личности и ваших потребностей. Мы предлагаем индивидуальные решения в реновации кухни, позволяя вам выбирать размер, планировку и материал, который лучше всего соответствует вашим предпочтениям. Будь то классический стиль загородного дома или современный дизайн, мы можем воплотить вашу мечту в жизнь."
            },
            PaintingPage: {
                title: "Покраска",
                description: "Добавьте новый слой краски, чтобы оживить ваши комнаты",
                text1: "Покраска - один из наиболее мощных и экономичных способов преобразить любое пространство. Мы специализируемся в профессиональных услугах по покраске, которые оживляют ваш дом или коммерческое помещение. Наша экспертная команда объединяет в себе креативность, точность и высококачественные материалы, чтобы доставить впечатляющие и долговечные результаты.",
                text2: "Внутренняя покраска",
                text3: "Наши услуги по внутренней покраске разработаны, чтобы создать свежую, привлекательную атмосферу в вашем доме. Независимо от того, вы хотите обновить одну комнату, оживить весь интерьер или просто добавить каплю цвета, у нас есть экспертиза, чтобы это осуществить. Мы работаем с вами, чтобы выбрать идеальную цветовую палитру, отражающую ваш стиль и дополняющую вашу декорацию.",
                text4: "Внешняя покраска",
                text5: "Внешний вид вашей собственности - это первое впечатление, которое у гостей и прохожих о ней складывается. Наши услуги по внешней покраске не только улучшают привлекательность снаружи, но и защищают вашу собственность от воздействия окружающей среды. Мы используем качественные краски и покрытия, которые выдерживают суровые погодные условия, обеспечивая, что ваша собственность будет выглядеть красиво в течение многих лет.",
                text6: "Коммерческая покраска",
                text7: "Для бизнеса хорошо сохраненный и профессионально покрашенный интерьер и экстерьер могут оказать значительное влияние на клиентов и сотрудников. Наши услуги по коммерческой покраске адаптированы для удовлетворения уникальных потребностей вашего бизнеса. Независимо от того, это офисное помещение, розничный магазин или промышленный объект, мы работаем эффективно, чтобы минимизировать нарушение ваших операций.",
                text8: "Внимание к деталям",
                text9: "Мы гордимся нашим вниманием к деталям и обязательством к качественному мастерству. Наши опытные художники готовят поверхности метикулезно, обеспечивая гладкое и безупречное покрытие. Мы также устраняем любые дефекты поверхности или несовершенства перед нанесением последних слоев краски, оставляя вас с профессиональными и полированными результатами."
            },
            ResidentialRemodelingPage: {
                title: "Реновация жилья",
                description: "Оживите свой дом с услугами по профессиональной реновации",
                text1: "Ваш дом нуждается в новом, вдохновляющем преобразовании? Не ищите дальше, чем наши услуги по реновации жилья. В нашей компании мы страстно относимся к вдохновлению новой жизни в вашем жилом пространстве, делая его более красивым, функциональным и ценным, чем когда-либо.",
                text2: "Наш подход",
                text3: "Наш подход к реновации жилья прост и эффективен: мы превращаем ваше видение в реальность. Независимо от того, вы представляете себе полный ремонт дома или целенаправленное преобразование конкретной области, мы здесь, чтобы привести ваши идеи в жизнь.",
                text4: "Качественное преобразование",
                text5: "Наше обязательство к качеству проявляется в каждом проекте по реновации. Мы тщательно выбираем материалы, применяем опытное мастерство и принимаем инновационные дизайнерские тенденции, чтобы обеспечить, что ваше пространство не только выглядит потрясающе, но и выдерживает испытание временем.",
                text6: "Повышенное качество жизни",
                text7: "Помимо эстетики, наши услуги по реновации жилья разработаны для повышения уровня вашей жизни. Мы перенастраиваем пространства, чтобы оптимизировать функциональность, повысить энергоэффективность и адаптировать ваш дом к вашему развивающемуся образу жизни.",
                text8: "Адаптировано к вам",
                text9: "Ваше мечтательное пространство - это ваше личное выражение, и мы ценим индивидуальность. Мы предлагаем решения, которые адаптированы к вашим предпочтениям, будь то дизайн интерьера, планировка, отделка или дополнительные улучшения."
            },
            ShowerpanPage: {
                title: "Установка Душевого Поддона",
                description: "Установите прочный душевой поддон для беспротечного опыта при принятии душа",
                text1: "Хорошо установленный душевой поддон - это незаслуженно забытый герой вашей ванной комнаты, обеспечивающий приятный и герметичный ежедневный ритуал. Мы специализируемся на установке душевых поддонов и гидроизоляции, обеспечивая важное основание для вашего душа, защищая ваш дом от водных повреждений и создавая прочное и красивое пространство.",
                text2: "Установка душевого поддона",
                text3: "Наши услуги по установке душевого поддона направлены на создание надежного и водонепроницаемого основания для вашего душа. Независимо от того, строите вы новый душ или заменяете старый, наша опытная команда опытно устанавливает душевой поддон, обеспечивая правильный слив и структурную целостность. Мы используем высококачественные материалы, такие как стекловолокно, акрил или кафель, чтобы соответствовать вашим дизайнерским предпочтениям.",
                text4: "Профессиональная гидроизоляция",
                text5: "Гидроизоляция имеет важное значение для предотвращения утечек и водных повреждений. Наши эксперты хорошо осведомлены в современных техниках и материалах гидроизоляции, обеспечивая, что ваш душ остается сухим, а стены и полы защищены. Мы уделяем максимальное внимание швам, углам и соединениям, чтобы гарантировать водонепроницаемое уплотнение.",
                text6: "Индивидуальный дизайн",
                text7: "Ваш душ должен отражать ваш стиль и потребности. Мы предлагаем индивидуальные решения для душевых поддонов, позволяя выбирать размер, форму и материал, который наилучшим образом соответствует вашим предпочтениям. Независимо от того, предпочитаете ли вы современный стиль или традиционный кафельный дизайн, мы можем воплотить вашу идею в жизнь.",
                text8: "Ремонт и модернизация",
                text9: "Если у вас возникли утечки или водные повреждения в вашем существующем душе, наши услуги по ремонту и модернизации могут помочь. Мы можем оценить состояние вашего душевого поддона, восстановить любые повреждения и улучшить гидроизоляцию для обеспечения долговечности вашего душа."
            },
            SinkPage: {
                title: "Установка Раковины",
                description: "Улучшите свою кухню стильной новой раковин",
                text1: "Раковина - это незаслуженно забытый герой вашей кухни, рабочий конь, который играет центральную роль в вашей повседневной кулинарной деятельности. Мы специализируемся на установке моек и услугах по модернизации, обеспечивая, что этот важный элемент не только соответствует вашим функциональным потребностям, но также дополняет общую эстетику вашей кухни.",
                text2: "Услуги по установке",
                text3: "Наши услуги по установке раковины направлены на создание надежной основы для функционирования вашей кухни. Независимо от того, строите ли вы новую кухню или заменяете устаревшую раковину, наша опытная команда гарантирует беспрепятственную установку. Мы предлагаем широкий выбор стилей моек, материалов и конфигураций, чтобы соответствовать вашим дизайнерским предпочтениям.",
                text4: "Улучшенная функциональность",
                text5: "Ваша раковина должна не только хорошо выглядеть, но и эффективно работать. Мы оптимизируем размещение и конфигурацию вашей раковины, чтобы упростить мытье посуды, приготовление пищи и уборку. Независимо от того, предпочитаете ли вы односекционную или двухсекционную раковиуну, мы подстраиваем конфигурацию под ваши специфические потребности.",
                text6: "Роскошные модернизации",
                text7: "Улучшите свою кухню с помощью высококачественной раковины, которая добавит штрих роскоши в ваше кулинарное пространство. Мы предлагаем варианты, такие как фермерские раковины, раковины под столешницей и стильные раковины из нержавеющей стали или гранитных композитных материалов. Наши модернизации раковины улучшают как функциональные, так и эстетические характеристики вашей кухни.",
                text8: "Ремонт и обслуживание",
                text9: "Если у вас возникли проблемы с существующей раковиной, мы предоставляем услуги по ремонту и обслуживанию раковины для восстановления ее функциональности. Будь то течь крана, поврежденная чаша или засоренный слив, мы можем диагностировать и устранить проблему, обеспечивая работу вашей раковины как новой.",
                text10: "Индивидуальные решения",
                text11: "Мы понимаем, что каждая кухня уникальна, и ваша раковина должна отражать ваш стиль. Мы предлагаем индивидуальные решения для моек, позволяя вам выбирать размер, форму и материал, который наилучшим образом соответствует вашим предпочтениям. Независимо от того, предпочитаете ли вы классическую фарфоровую раковину или современную раковину из нержавеющей стали, мы можем реализовать вашу идею."
            },
            WaterDamageRestorationPage: {
                title: "Восстановление после водных повреждений",
                description: "Восстановите свой дом после водных повреждений с нашими услугами восстановления",
                text1: "Хорошо установленный душевой поддон - это незаслуженно забытый герой вашей ванной комнаты, обеспечивающий приятный и герметичный ежедневный ритуал. Мы специализируемся на установке душевых поддонов и гидроизоляции, обеспечивая важное основание для вашего душа, защищая ваш дом от водных повреждений и создавая прочное и красивое пространство.",
                text2: "Установка душевого поддона",
                text3: "Наши услуги по установке душевого поддона направлены на создание надежного и водонепроницаемого основания для вашего душа. Независимо от того, строите вы новый душ или заменяете старый, наша опытная команда опытно устанавливает душевой поддон, обеспечивая правильный слив и структурную целостность. Мы используем высококачественные материалы, такие как стекловолокно, акрил или кафель, чтобы соответствовать вашим дизайнерским предпочтениям.",
                text4: "Гидроизоляционная экспертиза",
                text5: "Гидроизоляция имеет важное значение для предотвращения утечек и водных повреждений. Наши эксперты хорошо осведомлены в современных техниках и материалах гидроизоляции, обеспечивая, что ваш душ остается сухим, а стены и полы защищены. Мы уделяем максимальное внимание швам, углам и соединениям, чтобы гарантировать водонепроницаемое уплотнение.",
                text6: "Индивидуальный дизайн",
                text7: "Ваш душ должен отражать ваш стиль и потребности. Мы предлагаем индивидуальные решения для душевых поддонов, позволяя выбирать размер, форму и материал, который наилучшим образом соответствует вашим предпочтениям. Независимо от того, предпочитаете ли вы современный стиль или традиционный кафельный дизайн, мы можем воплотить вашу идею в жизнь."
            },
            WindowsPage: {
                title: "Установка Окон",
                description: "Улучшите ваши окна для повышенной энергоэффективности",
                text1: "Окна - это больше, чем просто ворота во внешний мир; они являются важными элементами аэстетики вашего дома, энергоэффективности и общего комфорта. Мы специализируемся на установке окон и услугах по ремонту, которые улучшают ваше жилое пространство более чем в одном отношении.",
                text2: "Услуги по установке",
                text3: "Наши услуги по установке окон разработаны, чтобы внести новую жизнь в ваш дом. Независимо от того, вы хотите заменить устаревшие окна, увеличить энергоэффективность или улучшить облик вашей недвижимости, у нас есть все необходимое. Наша опытная команда поможет вам выбрать идеальные окна, соответствующие вашему стилю и бюджету.",
                text4: "Энергоэффективность",
                text5: "Знаете ли вы, что окна играют важную роль в энергоэффективности вашего дома? Наши энергоэффективные решения для окон разработаны для обеспечения комфорта в вашем доме в течение всего года и экономии на коммунальных платежах. Мы предлагаем разнообразие вариантов, от двойных стекол до стекол с низкой эмиссией, с целью снизить теплопередачу и уменьшить проветривание.",
                text6: "Ремонт и обслуживание",
                text7: "Не допустите, чтобы поврежденные или неисправные окна подвергли опасности безопасность и комфорт вашего дома. Наши услуги по ремонту и обслуживанию окон могут решить проблемы, такие как разбитые стекла, неисправные замки и изношенные уплотнители. Своевременный ремонт гарантирует, что ваши окна будут выглядеть наилучшим образом и продлят их срок службы.",
                text8: "Индивидуальные решения",
                text9: "Мы понимаем, что каждый дом уникален, и ваши потребности в окнах могут различаться. Поэтому мы предлагаем индивидуальные решения, адаптированные под ваши требования. Независимо от того, предпочитаете ли вы классические, современные или специализированные окна, у нас есть опыт для воплощения вашей идеи в жизнь."
            },
            Wallpaper: {
                title: "Обои",
                description: "Преображение Стен со Стилем и Элегантностью",
                text1: "Обои - это универсальный и эффектный способ улучшить эстетическую привлекательность любой комнаты. Мы специализируемся на услугах по установке обоев, которые оживляют ваши стены с помощью узоров, текстур и цветов. Независимо от того, хотите ли вы добавить немного утонченности в вашу гостиную, создать уютную атмосферу в спальне или сделать смелое заявление в вашем офисе, наша команда экспертов готова помочь.",
                text2: "Наш Подход",
                text3: "Наш подход к установке обоев тщательный и адаптированный к вашему уникальному стилю и предпочтениям. Мы тесно сотрудничаем с вами, чтобы выбрать идеальные обои, которые дополняют ваш декор и соответствуют вашему дизайнерскому видению. От подготовки до окончательного нанесения наша команда обеспечивает безупречный финиш, который преобразит ваше пространство.",
                text4: "Выбор Дизайна",
                text5: "Выбор правильных обоев имеет решающее значение для достижения желаемого вида и ощущения в вашей комнате. Мы предлагаем широкий ассортимент дизайнов обоев, включая классические узоры, современные геометрические формы, текстурированные отделки и яркие настенные росписи. Наши эксперты помогут вам сориентироваться в вариантах и найти идеальное сочетание для вашего пространства.",
                text6: "Профессиональная Установка",
                text7: "Установка обоев требует точности и опыта. Наши квалифицированные установщики заботятся о каждой детали, от подготовки поверхности и измерений до резки и выравнивания обоев. Мы гарантируем, что узоры совпадают безупречно и нет пузырей или несовершенств, придавая вашим стенам профессиональный и отполированный вид.",
                text8: "Преображение Комнат",
                text9: "Обои могут кардинально изменить атмосферу любой комнаты. Независимо от того, хотите ли вы создать акцентную стену, добавить глубину и текстуру или наполнить комнату цветом, наши услуги по установке обеспечат потрясающие результаты. Мы работаем с различными пространствами, включая гостиные, спальни, столовые, офисы и многое другое."
            }
        }
    },
    es: {
        header: {
            home: "Inicio",
            services: "Servicios",
            portfolio: "Portafolio",
            about: "Acerca de"
        },
        homePage: {
            startMessage1: "Confía tu hogar",
            startMessage2: "a profesionales",
            contact: "Contacto",
            experience: "Experiencia",
            experienceDescription: "Más de 20 años de experiencia en remodelación",
            quality: "Calidad",
            qualityDescription: "Mejorando la calidad hasta la perfección",
            price: "Precio",
            priceDescription: "Precios competitivos en el mercado",
            clients: "Clientes",
            clientsDescription: "Más de 200 clientes y empresas satisfechos",
            result: "Resultado",
            resultDescription: "Haz realidad tus sueños",
            time: "Tiempo",
            timeDescription: "Realizamos el trabajo lo más rápido posible",
            mostPopularServices: "Servicios Más Populares",
            doors: "Puertas",
            windows: "Ventanas",
            flooring: "Pisos",
            showerPan: "Plato de Ducha",
            painting: "Pintura",
            sink: "Fregadero",
            drywall: "Tabiquería",
            carpentry: "Carpintería",
            viewMore: "Ver Más"
        },
        reviews: {
            title: "Lo que dicen nuestros clientes",
            list: [
                {name: "Michael S.", stars: 5, description: "Alex hizo un trabajo increíble remodelando mi cocina. El trabajo está muy bien hecho y luce increíble. Aprecio la atención al detalle y el esfuerzo puesto. Si desea una excelente experiencia de remodelación, este personal de mantenimiento es su mejor opción.", image: "review1.png"},
                {name: "Anna R.", stars: 5, description: "Trabajar con este equipo en la remodelación de nuestra casa fue muy sencillo. Tenían algunas ideas realmente interesantes e hicieron que nuestro lugar pareciera increíble. El equipo fue profesional y todo el proceso transcurrió sin estrés. No puedo agradecer lo suficiente a Alex por hacer que nuestro hogar fuera tan maravilloso. Si quieres a alguien que sea creativo, capacitado y que se preocupe por hacer un gran trabajo, definitivamente elígelo.", image: "review2.png"},
                {name: "Alex P.", stars: 5, description: "Contratar a Alex para la remodelación de nuestra casa fue la mejor decisión. El lugar parece totalmente diferente ahora, en el buen sentido. Fue fantástico trabajar con él y estoy muy contento con el resultado.", image: "review3.png"},
                {name: "Patrick B.", stars: 5, description: "Contraté personal de mantenimiento para una remodelación y fue una gran experiencia. Fueron unos profesionales totales y prestaron atención a cada detalle. El proyecto se terminó a tiempo y el resultado final fue de primera categoría. Si buscas a alguien que sea realmente bueno en lo que hace, te lo recomiendo ampliamente.", image: "review4.png"},
                {name: "Andrew C.", stars: 5, description: "Recientemente le pedí a Alex que hiciera algunas remodelaciones en mi casa y estoy encantado con el resultado. De principio a fin, fue increíble trabajar con él. Realmente se destacó la atención al detalle y la forma en que hizo todo lo posible. El resultado final fue mucho mejor de lo que esperaba. Si buscas a alguien que se preocupe por su trabajo y haga un trabajo fantástico, elige a Alex.", image: "review5.png"},
            ],
            starDescription: "haga clic en una estrella para evaluar el trabajo"
        },
        commonText: {
            title: "Acerca de mí",
            aboutMe: "Tengo más de 20 años de experiencia en remodelación de casas. Desde trabajos como autónomo hasta tener mi propia empresa.",
            textBetter: "Utilice únicamente mensajes de texto. De esta manera nos mantenemos mejor en contacto contigo, mantenemos nuestros acuerdos, no olvidamos nada y mejoramos nuestro servicio."
        },
        contactUs: {
            title: "Contáctenos",
            appointment: "PROGRAMAR CITA",
            estimate: "OBTENER UN PRESUPUESTO",
            namePlaceholder: "Su Nombre",
            phonePlaceholder: "Número de Teléfono",
            descriptionPlaceholder: "Cuénteme acerca de su proyecto",
            reviewTitle: "Escribe Tu Reseña",
            reviewDescriptionPlaceholder: "Tu Reseña",
            emailPlaceholder: "Correo Electrónico",
            upload: "Cargar foto",
            submit: "Enviar"
        },
        footer: {
            area: "Charlotte, Carolina del Norte",
            sms: "SMS",
            email: "Correo Electrónico",
            developer: "Desarrollador"
        },
        aboutPage: {
            title: "Acerca de",
            name: "Alex",
            areas: ["Southend", "Pineville", "Downtown", "Uptown", "Belmont", "Montclair South", "Lake Wylie", "Steel Creek", "Mountain Island", "Woodford Green", "Myers Park", "Collingwood", "Fort Mill", "Gastonía"]
        },
        servicesPage: {
            title: "Servicios",
            commonServices: "Servicios Comunes",
            residentialRenovation: "Remodelación Residencial",
            doors: "Puertas",
            windows: "Ventanas",
            flooring: "Pisos",
            painting: "Pintura",
            carpentry: "Carpintería",
            drywall: "Paneles de Yeso",
            homeImprovement: "Mejoras para el Hogar",
            bathroom: "Baño",
            bathroomRenovation: "Remodelación de Baño",
            showerPan: "Plato de Ducha",
            waterDamageRestoration: "Restauración por Daños de Agua",
            kitchen: "Cocina",
            kitchenRenovation: "Remodelación de Cocina",
            sink: "Fregadero",
            countertopInstallation: "Instalación de Encimeras",
        },
        portfolioPage: {
            title: "Mi Portafolio",
            showerPan: "Plato de Ducha",
            remodeling: "Remodelación",
            backsplash: "Salpicadero",
            ladder: "Escalera",
            laminate: "Laminado",
            projects: "Proyectos",
            beforeAfter: "Antes & Después"
        },
        services: {
            BathroomRemodelingPage: {
                title: "Remodelación de Baños",
                description: "Crea un Oasis de Lujo con una Remodelación de Baño",
                text1: "Tu baño es más que un espacio funcional; es un santuario donde comienzas y terminas tu día. Nos especializamos en servicios de remodelación de baños que transforman esta habitación esencial en un lujoso oasis de relajación y funcionalidad. Ya sea que sueñes con un refugio estilo spa o simplemente quieras actualizar el aspecto y la sensación de tu baño, estamos aquí para hacerlo realidad.",
                text2: "Nuestro Enfoque",
                text3: "Nuestro enfoque para la remodelación de baños se basa en tu visión y nuestra experiencia. Trabajamos estrechamente contigo para comprender tus preferencias, necesidades y presupuesto, asegurando que cada aspecto de tu transformación de baño se alinee con tu estilo de vida único. Desde la concepción hasta la finalización, nuestro dedicado equipo de expertos está contigo en cada paso del camino.",
                text4: "Estética Mejorada",
                text5: "Revitaliza la estética de tu baño con nuestros servicios de remodelación. Ofrecemos una amplia gama de opciones de diseño, desde el minimalismo contemporáneo hasta la elegancia atemporal. Nuestra atención al detalle garantiza que cada elemento, desde la selección de azulejos hasta las luminarias, contribuya a la atmósfera general que deseas.",
                text6: "Funcionalidad Mejorada",
                text7: "Un baño bien diseñado no solo debe lucir hermoso, sino que también debe funcionar sin problemas. Optimizamos la disposición y seleccionamos accesorios y materiales que mejoren la conveniencia y la eficiencia. Ya sea mediante soluciones que ahorren espacio, almacenamiento inteligente o características accesibles, nos aseguramos de que tu baño funcione para ti.",
                text8: "Mejoras de Lujo",
                text9: "Eleva tu baño con mejoras de lujo. Desde duchas inspiradas en spas y bañeras de remojo hasta calefacción por suelo radiante y acabados de alta calidad, añadimos un toque de opulencia a tu rutina diaria.",
                text10: "Personalización",
                text11: "Tu baño es tan único como tú, y aprovechamos la oportunidad de crear una solución personalizada que se adapte a tus necesidades específicas. Ya sea un diseño de tocador único, gabinetes a medida o patrones de azulejos personalizados, adaptamos tu baño a tu gusto."
            },
            CarpentryPage: {
                title: "Carpintería",
                description: "Crea Soluciones de Carpintería Personalizadas para un Toque Personal",
                text1: "La carpintería es un arte, una habilidad y una artesanía que agrega calidez, funcionalidad y carácter a cualquier espacio. Nos especializamos en servicios de carpintería experta que transforman tus ideas en trabajos de madera bellamente diseñados y personalizados. Desde detalles intrincados hasta mejoras estructurales, nuestro dedicado equipo de artesanos da vida a tus visiones.",
                text2: "Carpintería Personalizada",
                text3: "Nuestros servicios de carpintería personalizada se adaptan a tus necesidades y preferencias únicas. Ya sea que busques gabinetes a medida, estanterías empotradas, molduras intrincadas o una pieza de mobiliario única en su tipo, tenemos la experiencia para crearlo. Trabajamos estrechamente contigo para diseñar y construir trabajos de madera que complementen perfectamente tu espacio y estilo.",
                text4: "Mejoras Estructurales",
                text5: "La carpintería no se trata solo de estética, también se trata de la integridad estructural. Nuestro equipo puede reforzar o reparar estructuras existentes o crear nuevas, como terrazas, pérgolas o incluso ampliaciones de viviendas. Nuestro compromiso con la calidad garantiza que cada proyecto que emprendemos no solo sea hermoso, sino que también esté diseñado para durar.",
                text6: "Restauración y Reparaciones",
                text7: "Preservar la belleza de casas antiguas o restaurar trabajos de madera dañados es una pasión para nosotros. Tenemos las habilidades y el conocimiento para reparar y restaurar elementos de madera a su antigua gloria, ya sea en una casa histórica, muebles antiguos o estructuras exteriores deterioradas.",
                text8: "Detalles Minuciosos",
                text9: "La belleza de la carpintería radica en los detalles. Nuestros artesanos prestan atención meticulosa a cada aspecto del proyecto, desde medidas precisas hasta la selección de las mejores especies de madera. Nos aseguramos de que cada junta, curva y acabado se realice a la perfección."
            },
            CountertopInstallationPage: {
                title: "Instalación de Encimeras",
                description: "Elige la Encimera Perfecta para el Nuevo Aspecto de tu Cocina",
                text1: "Las encimeras son el punto focal de tu cocina y baño, marcando el tono de todo el espacio. Nos especializamos en servicios de instalación de encimeras que no solo mejoran la funcionalidad de tus espacios, sino que también agregan estilo y sofisticación. Ya sea que sueñes con un rincón culinario moderno o un refugio de baño tipo spa, estamos aquí para dar vida a tu visión.",
                text2: "Nuestro Enfoque",
                text3: "Nuestro enfoque para la instalación de encimeras se basa en la artesanía y la personalización. Trabajamos estrechamente contigo para comprender tus preferencias de diseño, presupuesto y necesidades de estilo de vida, asegurando que cada aspecto de tu proyecto se alinee con tu visión única. Desde la selección de materiales hasta los toques finales, nuestro dedicado equipo de expertos está contigo en cada paso del camino.",
                text4: "Encimeras de Cocina",
                text5: "Revitaliza la estética y la funcionalidad de tu cocina con nuestros servicios de instalación de encimeras. Ofrecemos una amplia gama de materiales, incluyendo granito, cuarzo, mármol y bloque de carnicero, para que coincidan con tu estilo. Nuestras medidas precisas y la instalación experta aseguran un ajuste perfecto que complementa tus gabinetes y electrodomésticos.",
                text6: "Encimeras de Baño",
                text7: "Mejora tu baño con encimeras elegantes y duraderas. Ofrecemos opciones como piedra natural, cuarzo engineered y materiales de superficie sólida que no solo añaden belleza, sino que también resisten las demandas del uso diario. Desde vanidades individuales hasta lujos con dos lavabos, adaptamos el diseño a tus necesidades.",
                text8: "Personalización",
                text9: "Tus encimeras deben reflejar tu personalidad y estilo de vida. Ofrecemos soluciones de encimeras personalizadas, lo que te permite elegir el color, la textura, el perfil del borde y el acabado que mejor se adapten a tus preferencias. Ya sea que prefieras un aspecto clásico y atemporal o un diseño moderno y elegante, podemos acomodar tu visión.",
                text10: "Reparaciones y Mantenimiento",
                text11: "Si tienes problemas con tus encimeras existentes, proporcionamos servicios de reparación y mantenimiento para restaurar su belleza y funcionalidad. Desde reparaciones menores hasta el sellado de nuevo, nos aseguramos de que tus encimeras luzcan y funcionen como nuevas."
            },
            DoorsPage: {
                title: "Instalación de Puertas",
                description: "Mejora la Apariencia de tu Hogar con Nuevas Puertas Impresionantes",
                text1: "Las puertas son las entradas a tu hogar, proporcionando seguridad, estilo y funcionalidad. Nos especializamos en instalación y mejoras de puertas, ofreciendo soluciones que no solo mejoran la estética de tu propiedad, sino que también mejoran la seguridad y eficiencia energética.",
                text2: "Servicios de Instalación",
                text3: "Nuestros servicios de instalación de puertas se centran en elevar la estética de tu hogar y diseño interior. Ya sea que desees reemplazar una antigua puerta principal, instalar una nueva puerta de patio o mejorar las puertas interiores, tenemos la experiencia para hacerlo realidad. Nuestra amplia selección de estilos de puertas, materiales y acabados asegura que encuentres el ajuste perfecto para tu gusto único.",
                text4: "Seguridad y Protección",
                text5: "La seguridad es primordial, y nuestras puertas están diseñadas pensando en tu seguridad. Ofrecemos una gama de opciones robustas y seguras, incluyendo puertas de entrada reforzadas con sistemas de bloqueo avanzados. Tu tranquilidad es nuestra principal prioridad.",
                text6: "Eficiencia Energética",
                text7: "Las puertas desempeñan un papel crucial en la eficiencia energética de tu hogar. Nuestras soluciones de puertas eficientes energéticamente están diseñadas para mantener tu hogar cómodo y reducir los costos de energía. Ofrecemos opciones como puertas aisladas con sellos herméticos que eliminan corrientes de aire, haciendo que tu espacio sea acogedor durante todo el año.",
                text8: "Personalización",
                text9: "Tu hogar debe reflejar tu personalidad y estilo. Es por eso que ofrecemos soluciones de puertas personalizadas que te permiten elegir el tamaño, diseño, color y herrajes que mejor se adapten a tu gusto y que complementen la arquitectura de tu hogar."
            },
            DrywallPage: {
                title: "Paneles de Yeso",
                description: "Repara y Renueva tus Paredes con Servicios Expertos de Paneles de Yeso",
                text1: "El panel de yeso, a menudo conocido como tablero de yeso o sheetrock, es el héroe oculto detrás de las paredes de tu hogar o espacio comercial. Nos especializamos en servicios profesionales de paneles de yeso que proporcionan la base esencial para tus paredes y techos. Nuestro equipo experimentado garantiza que tus espacios no solo sean estructuralmente sólidos, sino también estéticamente impecables.",
                text2: "Servicios de Instalación",
                text3: "Nuestros servicios de instalación de paneles de yeso son el primer paso para crear una superficie uniforme y suave para tus paredes y techos. Ya sea que estés construyendo un edificio nuevo, remodelando o necesitas reparaciones, nuestros expertos tienen el conocimiento y la experiencia para manejar proyectos de todos los tamaños. Utilizamos materiales de paneles de yeso de alta calidad, garantizando durabilidad y longevidad.",
                text4: "Acabado sin Costuras",
                text5: "La clave para un acabado interior impecable radica en un acabado de paneles de yeso experto. Nuestro equipo presta atención meticulosa a los detalles, asegurando que las juntas estén perfectamente ocultas, las superficies sean lisas y las esquinas sean nítidas. Nuestros toques finales crean un lienzo sin fisuras para tu pintura o papel tapiz, mejorando la estética general de tu espacio.",
                text6: "Reparaciones y Parches",
                text7: "Desde abolladuras y golpes menores hasta daños más extensos, proporcionamos servicios de reparación y parches de paneles de yeso para restaurar la integridad de tus paredes y techos. Ya sea daño por agua, agujeros o grietas, tenemos la experiencia para que tus superficies luzcan como nuevas.",
                text8: "Personalización",
                text9: "Entendemos que cada proyecto es único y ofrecemos soluciones personalizadas de paneles de yeso para satisfacer tus necesidades específicas. Ya sea que necesites texturas especiales, aislamiento acústico o paneles de yeso resistentes al fuego, podemos adaptar nuestros servicios para cumplir con tus requisitos."
            },
            FlooringPage: {
                title: "Pisos",
                description: "Transforma tu Espacio con Hermosas Opciones de Pisos Nuevos",
                text1: "Los pisos son el fundamento del interior de tu hogar, estableciendo el escenario para su estética general, comodidad y funcionalidad. Nos especializamos en servicios de instalación y transformación de pisos que insuflan nueva vida en tus espacios habitables, creando un ambiente que refleja tu estilo y mejora tu vida diaria.",
                text2: "Servicios de Instalación",
                text3: "Nuestros servicios de instalación de pisos están diseñados para elevar el interior de tu hogar. Ya sea que estés buscando reemplazar pisos desgastados, actualizar tu estilo o aumentar el valor de tu propiedad, nuestro equipo de expertos está aquí para guiarte. Ofrecemos una amplia gama de materiales para pisos, incluyendo madera dura, laminado, azulejos, vinilo y alfombras, asegurando que encuentres la combinación perfecta para tu visión.",
                text4: "Mejora Estética",
                text5: "Tu elección de pisos juega un papel fundamental en la estética general de tu hogar. Nuestras soluciones de pisos están diseñadas no solo para lucir hermosas, sino también para resistir la prueba del tiempo. Desde elegancia clásica hasta estilo contemporáneo, tenemos las opciones de pisos perfectas para complementar tu estilo único.",
                text6: "Funcionalidad y Durabilidad",
                text7: "Los pisos son más que una superficie: es donde vives, juegas y creas recuerdos. Seleccionamos materiales de pisos por su durabilidad y facilidad de mantenimiento, asegurando que tus pisos se mantengan hermosos y funcionales durante años.",
                text8: "Diseño Personalizado",
                text9: "Entendemos que cada hogar es único, y tus pisos también deberían serlo. Es por eso que ofrecemos soluciones de diseño personalizado que te permiten elegir los patrones, colores y texturas que mejor se adapten a tus preferencias y estilo de vida."
            },
            HomeImprovementPage: {
                title: "Mejoras para el Hogar",
                description: "Aumenta el Valor General de tu Hogar con Expertos en Mejoras para el Hogar",
                text1: "Tu hogar es más que una estructura: es un reflejo de tu personalidad, un santuario para tu familia y una inversión en tu futuro. En nuestra compañía, nos especializamos en servicios de mejoras para el hogar que realzan la belleza, comodidad y funcionalidad de tus espacios habitables. Ya sea que desees actualizar una sola habitación o emprender una renovación completa de tu hogar, estamos aquí para convertir tu visión en realidad.",
                text2: "Nuestro Enfoque",
                text3: "Nuestro enfoque para las mejoras en el hogar se centra en la colaboración y la artesanía. Trabajamos estrechamente contigo para comprender tus objetivos, preferencias y presupuesto, asegurando que cada aspecto de tu proyecto se adapte a tus necesidades únicas. Desde la concepción hasta la finalización, nuestro dedicado equipo de expertos está contigo en cada paso del camino.",
                text4: "Mejoras en el Interior",
                text5: "Revitaliza el corazón de tu hogar con nuestros servicios de mejoras interiores. Ya sea una remodelación de cocina moderna, una lujosa renovación de baño o la creación de un espacio habitable funcional y elegante, tenemos la experiencia para dar vida a tus ideas. Nuestra atención al detalle asegura que cada rincón de tu interior esté bellamente acabado.",
                text6: "Mejoras en el Exterior",
                text7: "Mejora la apariencia y funcionalidad de tu hogar con nuestras soluciones de mejora en el exterior. Desde el reemplazo de revestimientos hasta la instalación de ventanas eficientes en energía, mejoramos la integridad estructural y la eficiencia energética de tu propiedad, al tiempo que la hacemos más atractiva visualmente.",
                text8: "Renovación y Remodelación",
                text9: "Renovar o remodelar tu hogar es un proyecto importante, y estamos aquí para que el proceso sea sin problemas. Nuestro equipo de artesanos calificados, diseñadores y directores de proyectos se dedica a entregar resultados excepcionales dentro de tus plazos y restricciones de presupuesto.",
                text10: "Personalización",
                text11: "Cada hogar es único, y abrazamos la oportunidad de crear soluciones personalizadas que se adapten a tus requisitos específicos. Ya sea que necesites gabinetes a medida, estanterías empotradas o paisajismo personalizado, convertimos tus sueños en realidad con atención meticulosa a los detalles."
            },
            KitchenRemodelingPage: {
                title: "Remodelación de Cocina",
                description: "Cocina tu Cocina de Ensueño con una Remodelación Completa",
                text1: "Tu cocina es el corazón de tu hogar, donde se crean recuerdos entrañables y deliciosas comidas. Nos especializamos en servicios de remodelación de cocinas que infunden nueva vida en este espacio vital. Ya sea que sueñes con un refugio culinario moderno, un acogedor lugar de encuentro familiar o simplemente desees actualizar el aspecto y la sensación de tu cocina, estamos aquí para hacerlo realidad.",
                text2: "Nuestro Enfoque",
                text3: "Nuestro enfoque para la remodelación de cocinas se basa en la colaboración y la artesanía. Trabajamos estrechamente contigo para comprender tus objetivos, preferencias y presupuesto, asegurando que cada aspecto de tu transformación de cocina se alinee con tu estilo de vida y personalidad únicos. Desde el diseño hasta la finalización, nuestro dedicado equipo de expertos está contigo en cada paso del camino.",
                text4: "Estética Mejorada",
                text5: "Revitaliza la estética de tu cocina con nuestros servicios de remodelación. Ofrecemos una amplia gama de opciones de diseño, desde minimalismo contemporáneo hasta elegancia atemporal. Nuestra atención al detalle garantiza que cada elemento, desde los gabinetes hasta las encimeras, contribuya a la atmósfera general que deseas.",
                text6: "Funcionalidad Mejorada",
                text7: "Una cocina bien diseñada no solo debe verse hermosa, sino también funcionar sin problemas. Optimizamos la distribución y seleccionamos accesorios y materiales que mejoran la comodidad y la eficiencia. Ya sea soluciones que ahorren espacio, almacenamiento inteligente o electrodomésticos modernos, nos aseguramos de que tu cocina funcione para ti.",
                text8: "Mejoras Lujosas",
                text9: "Eleva tu cocina con mejoras lujosas. Desde electrodomésticos inspirados en chefs y gabinetes personalizados hasta hermosos salpicaderos y acabados de alta gama, agregamos un toque de opulencia a tu espacio culinario.",
                text10: "Personalización",
                text11: "Tu cocina es un reflejo de tu personalidad y necesidades. Ofrecemos soluciones personalizadas de remodelación de cocinas que te permiten elegir el tamaño, diseño y material que mejor se adapte a tus preferencias. Ya sea que prefieras un estilo clásico de granja o un diseño moderno y elegante, podemos hacer realidad tu visión."
            },
            PaintingPage: {
                title: "Pintura",
                description: "Añade una Capa Fresca de Pintura para Revivir tus Habitaciones",
                text1: "La pintura es una de las formas más poderosas y rentables de transformar cualquier espacio. Nos especializamos en servicios profesionales de pintura que infunden nueva vida en tu hogar o propiedad comercial. Nuestro equipo de expertos combina creatividad, precisión y materiales de alta calidad para ofrecer resultados impresionantes y duraderos.",
                text2: "Pintura de Interiores",
                text3: "Nuestros servicios de pintura de interiores están diseñados para crear un ambiente fresco y acogedor en tu hogar. Ya sea que desees actualizar una sola habitación, revitalizar todo tu interior o simplemente añadir un toque de color, tenemos la experiencia para hacerlo realidad. Trabajamos contigo para elegir la paleta de colores perfecta que refleje tu estilo y complemente tu decoración.",
                text4: "Pintura de Exteriores",
                text5: "La fachada de tu propiedad es la primera impresión que los visitantes y transeúntes tienen de tu espacio. Nuestros servicios de pintura de exteriores no solo mejoran la apariencia, sino que también protegen tu propiedad de los elementos. Utilizamos pinturas y acabados de alta calidad que resisten las condiciones climáticas más adversas, asegurando que tu propiedad luzca hermosa durante años.",
                text6: "Pintura Comercial",
                text7: "Para las empresas, un interior y exterior bien mantenido y profesionalmente pintado puede tener un gran impacto en los clientes y empleados por igual. Nuestros servicios de pintura comercial están diseñados para satisfacer las necesidades únicas de tu negocio. Ya sea un espacio de oficina, una tienda minorista o una instalación industrial, trabajamos de manera eficiente para minimizar las interrupciones en tus operaciones.",
                text8: "Atención al Detalle",
                text9: "Nos enorgullece nuestra atención al detalle y nuestro compromiso con la artesanía de calidad. Nuestros pintores calificados preparan las superficies minuciosamente, asegurando un acabado suave y sin defectos. También abordamos cualquier reparación o imperfección en la superficie antes de aplicar las capas finales de pintura, dejándote con un resultado profesional y pulido."
            },
            ResidentialRemodelingPage: {
                title: "Remodelación Residencial",
                description: "Revitaliza tu Hogar con Servicios de Remodelación Expertos",
                text1: "¿Necesita tu hogar una transformación fresca e inspiradora? No busques más allá de nuestros servicios de remodelación residencial. En nuestra empresa, estamos apasionados por infundir nueva vida en tus espacios habitables, haciéndolos más hermosos, funcionales y valiosos que nunca.",
                text2: "Nuestro Enfoque",
                text3: "Nuestro enfoque de remodelación residencial es simple pero efectivo: convertimos tu visión en realidad. Ya sea que estés imaginando una remodelación completa de tu hogar o una transformación específica de un área determinada, estamos aquí para dar vida a tus ideas.",
                text4: "Transformación de Calidad",
                text5: "Nuestro compromiso con la calidad se refleja en cada proyecto de remodelación. Seleccionamos cuidadosamente los materiales, empleamos la artesanía experta y adoptamos tendencias de diseño innovadoras para asegurarnos de que tu espacio no solo se vea increíble, sino que también resista la prueba del tiempo.",
                text6: "Mejora de la Vida",
                text7: "Más allá de la estética, nuestros servicios de remodelación residencial están diseñados para mejorar la forma en que vives. Reconfiguramos los espacios para optimizar la funcionalidad, aumentar la eficiencia energética y adaptar tu hogar a tu estilo de vida en evolución.",
                text8: "A Tu Medida",
                text9: "Tu espacio de ensueño es nuestra prioridad. Ya sea que tengas una visión clara o necesites ayuda para definirla, estamos aquí para guiarte. Nuestro objetivo es hacer que tu experiencia de remodelación sea sin problemas y agradable."
            },
            ShowerpanPage: {
                title: "Instalación de Plato de Ducha",
                description: "Instala una Duradera Bandeja de Ducha para una Experiencia de Baño Libre de Fugas",
                text1: "Una bandeja de ducha bien instalada es el héroe anónimo de tu baño, asegurando que tu ritual diario siga siendo placentero y libre de fugas. Nos especializamos en servicios de instalación de bandejas de ducha y impermeabilización que brindan la base esencial para tu ducha, protegiendo tu hogar contra daños por agua y creando un espacio duradero y hermoso.",
                text2: "Instalación de Bandejas de Ducha",
                text3: "Nuestros servicios de instalación de bandejas de ducha se centran en crear una base sólida e impermeable para tu ducha. Ya sea que estés construyendo una nueva ducha o reemplazando una antigua, nuestro experimentado equipo instalará expertamente la bandeja de ducha para garantizar un drenaje adecuado y la integridad estructural. Utilizamos materiales de alta calidad, como fibra de vidrio, acrílico o azulejos, para satisfacer tus preferencias de diseño.",
                text4: "Experiencia en Impermeabilización",
                text5: "La impermeabilización es crucial para prevenir fugas y daños por agua. Nuestros expertos están familiarizados con las últimas técnicas y materiales de impermeabilización, asegurando que tu ducha se mantenga seca y que tus paredes y suelos estén protegidos. Prestamos meticulosa atención a las costuras, esquinas y juntas para garantizar un sellado hermético.",
                text6: "Diseño Personalizado",
                text7: "Tu ducha debe reflejar tu estilo y necesidades. Ofrecemos soluciones personalizadas para bandejas de ducha, lo que te permite elegir el tamaño, la forma y el material que mejor se adapte a tus preferencias. Ya sea que prefieras un aspecto moderno y elegante o un diseño de azulejos tradicional, podemos dar vida a tu visión.",
                text8: "Reparaciones y Actualizaciones",
                text9: "Si estás experimentando fugas o daños por agua en tu ducha existente, nuestros servicios de reparación y actualización pueden ayudar. Podemos evaluar el estado de tu bandeja de ducha, reparar cualquier daño y mejorar la impermeabilización para garantizar la durabilidad de tu ducha."
            },
            SinkPage: {
                title: "Instalación del Fregadero",
                description: "Actualiza tu Cocina con un Nuevo Fregadero con Estilo",
                text1: "El fregadero es el héroe anónimo de tu cocina, un trabajador incansable que desempeña un papel central en tus actividades culinarias diarias. Nos especializamos en servicios de instalación y actualización de fregaderos, asegurando que este accesorio vital no solo satisfaga tus necesidades funcionales, sino que también complemente la estética general de tu cocina.",
                text2: "Servicios de Instalación",
                text3: "Nuestros servicios de instalación de fregaderos se centran en proporcionar una base sólida para la funcionalidad de tu cocina. Ya sea que estés construyendo una nueva cocina o reemplazando un fregadero desactualizado, nuestro experimentado equipo garantiza que la instalación sea impecable. Ofrecemos una amplia gama de estilos, materiales y configuraciones de fregaderos para que coincidan con tus preferencias de diseño.",
                text4: "Funcionalidad Mejorada",
                text5: "Tu fregadero no solo debe verse genial, sino también funcionar de manera eficiente. Optimizamos la ubicación y el diseño de tu fregadero para facilitar el lavado de platos, la preparación de alimentos y la limpieza. Desde fregaderos de un solo recipiente hasta fregaderos de doble recipiente, adaptamos la configuración a tus necesidades específicas.",
                text6: "Actualizaciones de Lujo",
                text7: "Actualiza tu cocina con un fregadero de alta gama que añade un toque de lujo a tu espacio culinario. Ofrecemos opciones como fregaderos de granja, fregaderos bajo encimera y modelos elegantes de acero inoxidable o compuesto de granito. Nuestras actualizaciones de fregaderos mejoran tanto la funcionalidad como la estética de tu cocina.",
                text8: "Reparaciones y Mantenimiento",
                text9: "Si estás experimentando problemas con tu fregadero actual, ofrecemos servicios de reparación y mantenimiento de fregaderos para restaurar su funcionalidad. Ya sea un grifo con fugas, un recipiente dañado o un desagüe obstruido, podemos diagnosticar y solucionar el problema, asegurando que tu fregadero funcione como nuevo.",
                text10: "Personalización",
                text11: "Entendemos que cada cocina es única y que tus necesidades de fregaderos pueden ser diferentes. Por eso, ofrecemos soluciones personalizadas de fregaderos que te permiten elegir el tamaño, la forma y el material que mejor se adapte a tus preferencias. Ya sea que prefieras un fregadero de porcelana clásico o un modelo de acero inoxidable moderno y elegante, podemos adaptarnos a tu visión."
            },
            WaterDamageRestorationPage: {
                title: "Restauración de Daños por Agua",
                description: "Restaura tu Hogar Después de Daños por Agua con Nuestros Servicios de Restauración",
                text1: "Una bandeja de ducha bien instalada es el héroe anónimo de tu baño, asegurando que tu ritual diario siga siendo placentero y libre de fugas. Nos especializamos en servicios de instalación de bandejas de ducha y impermeabilización que brindan la base esencial para tu ducha, protegiendo tu hogar contra daños por agua y creando un espacio duradero y hermoso.",
                text2: "Instalación de Bandejas de Ducha",
                text3: "Nuestros servicios de instalación de bandejas de ducha se centran en crear una base sólida e impermeable para tu ducha. Ya sea que estés construyendo una nueva ducha o reemplazando una antigua, nuestro experimentado equipo instalará expertamente la bandeja de ducha para garantizar un drenaje adecuado y la integridad estructural. Utilizamos materiales de alta calidad, como fibra de vidrio, acrílico o azulejos, para satisfacer tus preferencias de diseño.",
                text4: "Experiencia en Impermeabilización",
                text5: "La impermeabilización es crucial para prevenir fugas y daños por agua. Nuestros expertos están familiarizados con las últimas técnicas y materiales de impermeabilización, asegurando que tu ducha se mantenga seca y que tus paredes y suelos estén protegidos. Prestamos meticulosa atención a las costuras, esquinas y juntas para garantizar un sellado hermético.",
                text6: "Diseño Personalizado",
                text7: "Tu ducha debe reflejar tu estilo y necesidades. Ofrecemos soluciones personalizadas para bandejas de ducha, lo que te permite elegir el tamaño, la forma y el material que mejor se adapte a tus preferencias. Ya sea que prefieras un aspecto moderno y elegante o un diseño de azulejos tradicional, podemos dar vida a tu visión.",
                text8: "Reparaciones y Actualizaciones",
                text9: "Si estás experimentando fugas o daños por agua en tu ducha existente, nuestros servicios de reparación y actualización pueden ayudar. Podemos evaluar el estado de tu bandeja de ducha, reparar cualquier daño y mejorar la impermeabilización para garantizar la durabilidad de tu ducha."
            },
            WindowsPage: {
                title: "Instalación de Ventanas",
                description: "Actualiza tus Ventanas para una Mayor Eficiencia Energética",
                text1: "Las ventanas son más que simples portales al mundo exterior; son elementos esenciales de la estética, eficiencia energética y comodidad general de tu hogar. Nos especializamos en servicios de instalación y reparación de ventanas que mejoran tu espacio habitable de múltiples maneras.",
                text2: "Servicios de Instalación",
                text3: "Nuestros servicios de instalación de ventanas están diseñados para dar nueva vida a tu hogar. Ya sea que desees reemplazar ventanas anticuadas, aumentar la eficiencia energética o mejorar la apariencia de tu propiedad, estamos aquí para ayudarte. Nuestro equipo de expertos te asistirá en la selección de las ventanas perfectas que coincidan con tu estilo y presupuesto.",
                text4: "Eficiencia Energética",
                text5: "¿Sabías que las ventanas desempeñan un papel significativo en la eficiencia energética de tu hogar? Nuestras soluciones de ventanas eficientes en energía están diseñadas para mantener tu hogar cómodo durante todo el año mientras te ahorran dinero en las facturas de servicios públicos. Ofrecemos una variedad de opciones, desde vidrio doble hasta vidrio de baja emisividad, todas diseñadas para reducir la transferencia de calor y minimizar las corrientes de aire.",
                text6: "Reparación y Mantenimiento",
                text7: "No permitas que las ventanas dañadas o que funcionan mal comprometan la seguridad y comodidad de tu hogar. Nuestros servicios de reparación y mantenimiento de ventanas pueden abordar problemas como cristales rotos, cerraduras defectuosas y juntas desgastadas. Las reparaciones oportunas no solo garantizan que tus ventanas luzcan lo mejor posible, sino que también prolongan su vida útil.",
                text8: "Soluciones Personalizadas",
                text9: "Entendemos que cada hogar es único y que tus necesidades de ventanas pueden variar. Por eso, ofrecemos soluciones personalizadas adaptadas a tus especificaciones. Ya sea que prefieras ventanas clásicas, modernas o especializadas, tenemos la experiencia para dar vida a tu visión."
            },
            Wallpaper: {
                title: "Papel Pintado",
                description: "Transformando Paredes con Estilo y Elegancia",
                text1: "El papel pintado es una forma versátil e impactante de mejorar el atractivo estético de cualquier habitación. Nos especializamos en servicios de instalación de papel pintado que dan vida a tus paredes con patrones, texturas y colores. Ya sea que desees agregar un toque de sofisticación a tu sala de estar, crear un ambiente acogedor en tu dormitorio o hacer una declaración audaz en tu oficina, nuestro equipo de expertos está aquí para ayudarte.",
                text2: "Nuestro Enfoque",
                text3: "Nuestro enfoque para la instalación de papel pintado es meticuloso y se adapta a tu estilo y preferencias únicas. Trabajamos estrechamente contigo para seleccionar el papel pintado perfecto que complemente tu decoración y cumpla con tu visión de diseño. Desde la preparación hasta la aplicación final, nuestro equipo garantiza un acabado impecable que transforma tu espacio.",
                text4: "Selección de Diseño",
                text5: "Elegir el papel pintado adecuado es crucial para lograr el aspecto y la sensación deseados en tu habitación. Ofrecemos una amplia gama de diseños de papel pintado, incluyendo patrones clásicos, geométricos modernos, acabados texturizados y murales vibrantes. Nuestros expertos pueden ayudarte a navegar por las opciones y encontrar la combinación perfecta para tu espacio.",
                text6: "Instalación Profesional",
                text7: "La instalación de papel pintado requiere precisión y experiencia. Nuestros instaladores capacitados se encargan de cada detalle, desde la preparación de la superficie y la medición hasta el corte y la alineación del papel pintado. Nos aseguramos de que los patrones coincidan a la perfección y de que no haya burbujas ni imperfecciones, dando a tus paredes un aspecto profesional y pulido.",
                text8: "Transformaciones de Habitaciones",
                text9: "El papel pintado puede cambiar drásticamente el ambiente de cualquier habitación. Ya sea que desees crear una pared destacada, agregar profundidad y textura, o infundir color en una habitación, nuestros servicios de instalación ofrecen resultados sorprendentes. Trabajamos en diversos espacios, incluyendo salas de estar, dormitorios, comedores, oficinas y más."
            }
        }
    }
}