import React, {useContext} from "react";
import {LanguageContext} from "../../App";
import classes from "../../styles/component_styles/services.module.scss";
import image from "../../images/services/Flooring.jpg";
import {GiFlatPlatform} from "react-icons/gi";
import {Col, Container, Row} from "react-bootstrap";
import {Helmet} from "react-helmet";

function FlooringPage() {

    const languageContext = useContext(LanguageContext);

    return(
        <>

            <Helmet>
                <title>Flooring Services in Charlotte NC</title>
                <meta
                    name="description"
                    content="Explore premier flooring services in Charlotte NC. From hardwood to tile, our expert team delivers quality installations and repairs for your home."
                />
            </Helmet>

            <div className={classes.start_image}>
                <h1 className={classes.start_message}><GiFlatPlatform className={classes.servicesIcon}/> {languageContext.services.FlooringPage.title}</h1>
                <p className={classes.darkText}>{languageContext.services.FlooringPage.description}</p>
            </div>

            <Container>
                <Row>
                    <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                        <p className={classes.description}>
                            {languageContext.services.FlooringPage.text1}
                            <br/><br/>
                            <span>{languageContext.services.FlooringPage.text2}</span>
                            <br/>
                            {languageContext.services.FlooringPage.text3}
                            <br/><br/>
                            <span>{languageContext.services.FlooringPage.text4}</span>
                            <br/>
                            {languageContext.services.FlooringPage.text5}
                            <br/><br/>
                            <span>{languageContext.services.FlooringPage.text6}</span>
                            <br/>
                            {languageContext.services.FlooringPage.text7}
                            <br/><br/>
                            <span>{languageContext.services.FlooringPage.text8}</span>
                            <br/>
                            {languageContext.services.FlooringPage.text9}
                            <br/><br/>
                            <span>{languageContext.services.FlooringPage.text10}</span>
                            <br/>
                            {languageContext.services.FlooringPage.text11}
                        </p>
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                        <img src={image} className={classes.image}/>
                    </Col>
                </Row>
            </Container>
        </>
    );

}

export default FlooringPage;