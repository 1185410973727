import React, {useContext} from "react";
import {LanguageContext} from "../../App";
import classes from "../../styles/component_styles/services.module.scss";
import image from '../../images/services/BathroomRenovation.jpg';
import {FaBath} from "react-icons/fa";
import {Col, Container, Row} from "react-bootstrap";
import {Helmet} from "react-helmet";

function BathroomRemodelingPage() {

    const languageContext = useContext(LanguageContext);

    return(
        <>

            <Helmet>
                <title>Bathroom Remodeling Services in Charlotte NC</title>
                <meta
                    name="description"
                    content="Discover premier bathroom remodeling services in Charlotte NC. Expert solutions for showers, tiles, fixtures, and more. Transform your bathroom with us!"
                />
            </Helmet>

            <div className={classes.start_image}>
                <h1 className={classes.start_message}><FaBath className={classes.servicesIcon}/> {languageContext.services.BathroomRemodelingPage.title}</h1>
                <p className={classes.darkText}>{languageContext.services.BathroomRemodelingPage.description}</p>
            </div>

            <Container>
                <Row>
                    <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                        <p className={classes.description}>
                            {languageContext.services.BathroomRemodelingPage.text1}
                            <br/><br/>
                            <span>{languageContext.services.BathroomRemodelingPage.text2}</span>
                            <br/>
                            {languageContext.services.BathroomRemodelingPage.text3}
                            <br/><br/>
                            <span>{languageContext.services.BathroomRemodelingPage.text4}</span>
                            <br/>
                            {languageContext.services.BathroomRemodelingPage.text5}
                            <br/><br/>
                            <span>{languageContext.services.BathroomRemodelingPage.text6}</span>
                            <br/>
                            {languageContext.services.BathroomRemodelingPage.text7}
                            <br/><br/>
                            <span>{languageContext.services.BathroomRemodelingPage.text8}</span>
                            <br/>
                            {languageContext.services.BathroomRemodelingPage.text9}
                            <br/><br/>
                            <span>{languageContext.services.BathroomRemodelingPage.text10}</span>
                            <br/>
                            {languageContext.services.BathroomRemodelingPage.text11}
                        </p>
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                        <img src={image} className={classes.image}/>
                    </Col>
                </Row>
            </Container>
        </>
    );

}

export default BathroomRemodelingPage;