import React from "react";
import AppointmentForm from "./AppointmentForm";
import EstimateForm from "./EstimateForm";

function Form(props) {
    if(props.selection == props.appointment){
        return(
            <AppointmentForm languageContext={props.languageContext} onSubmitAppointment={props.onSubmitAppointment}/>
        );
    }
    else{
        return(
            <EstimateForm languageContext={props.languageContext} onSubmitEstimate={props.onSubmitEstimate}/>
        );
    }
}

export default Form;