import React, {useState} from "react";
import classes from "../styles/component_styles/contactUs.module.scss";
import {BsCamera} from "react-icons/bs";

function EstimateForm(props) {

    const [estimateName, setEstimateName] = useState("");
    const [estimateNumber, setEstimateNumber] = useState("");
    const [estimateEmail, setEstimateEmail] = useState("");
    const [estimateDescription, setEstimateDescription] = useState("");

    const photo1 = React.createRef();
    const photo2 = React.createRef();
    const photo3 = React.createRef();

    const [selectedPhoto1, setSelectedPhoto1] = useState({result: null, file: null});
    const [selectedPhoto2, setSelectedPhoto2] = useState({result: null, file: null});
    const [selectedPhoto3, setSelectedPhoto3] = useState({result: null, file: null});

    const handlePhotoUpload = (event, setSelectedPhoto) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            const newObj = {result: e.target.result, file: file};
            setSelectedPhoto(newObj);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const UploadedFile = ({selectedPhoto}) => {
        if(selectedPhoto == null){
            return(
                <div className={classes.avatar_zone}></div>
            );
        }else{
            return(
                <div className={classes.avatar_zone} style={{ background: `url(${selectedPhoto}) center/cover no-repeat` }}></div>
            );
        }
    }

    const CameraIcon = ({selectedPhoto}) => {
        if(selectedPhoto == null){
            return(
                <BsCamera className={classes.camera_icon}/>
            );
        }else{
            return(
                <BsCamera className={classes.camera_icon} style={{ opacity: "0" }}/>
            );
        }
    }

    const onSubmitEstimate = () => {
        if((estimateName != "") && (estimateNumber != "") && (estimateEmail != "") && (estimateDescription != "")) {
            const photos = {
                photo1: { ...selectedPhoto1 },
                photo2: { ...selectedPhoto2 },
                photo3: { ...selectedPhoto3 },
            };

            props.onSubmitEstimate(estimateName, estimateNumber, estimateEmail, estimateDescription, photos);

            setEstimateName("");
            setEstimateNumber("");
            setEstimateEmail("");
            setEstimateDescription("");

            const resetObj = {result: null, file: null};
            setSelectedPhoto1(resetObj);
            setSelectedPhoto2(resetObj);
            setSelectedPhoto3(resetObj);
        }
    }

    return(
        <div key={"EstimateForm"}>
            <input type="text" placeholder={props.languageContext.contactUs.namePlaceholder} value={estimateName} onChange={e => setEstimateName(e.target.value)} className={classes.text_input}/>
            <br/>
            <input type="number" placeholder={props.languageContext.contactUs.phonePlaceholder} value={estimateNumber} onChange={e => setEstimateNumber(e.target.value)} className={classes.text_input}/>
            <br/>
            <input type="email" placeholder={props.languageContext.contactUs.emailPlaceholder} value={estimateEmail} onChange={e => setEstimateEmail(e.target.value)} className={classes.text_input}/>
            <br/>
            <input type="text" placeholder={props.languageContext.contactUs.descriptionPlaceholder} value={estimateDescription} onChange={e => setEstimateDescription(e.target.value)} className={classes.text_input}/>
            <br/>
            <div className={classes.files}>
                <div className={classes.file_input}>
                    <UploadedFile selectedPhoto={selectedPhoto1.result}/>
                    <input type="file" ref={photo1} className={classes.upload_btn} accept="image/png, image/jpeg, image/jpg, image/gif, image/tif" onChange={(e) => handlePhotoUpload(e, setSelectedPhoto1)}/>
                    <div className={classes.overlay_layer}>{props.languageContext.contactUs.upload}</div>
                    <CameraIcon selectedPhoto={selectedPhoto1.result}/>
                </div>
                <div className={classes.file_input}>
                    <UploadedFile selectedPhoto={selectedPhoto2.result}/>
                    <input type="file" ref={photo2} className={classes.upload_btn} accept="image/png, image/jpeg, image/jpg, image/gif, image/tif" onChange={(e) => handlePhotoUpload(e, setSelectedPhoto2)}/>
                    <div className={classes.overlay_layer}>{props.languageContext.contactUs.upload}</div>
                    <CameraIcon selectedPhoto={selectedPhoto2.result}/>
                </div>
                <div className={classes.file_input}>
                    <UploadedFile selectedPhoto={selectedPhoto3.result}/>
                    <input type="file" ref={photo3} className={classes.upload_btn} accept="image/png, image/jpeg, image/jpg, image/gif, image/tif" onChange={(e) => handlePhotoUpload(e, setSelectedPhoto3)}/>
                    <div className={classes.overlay_layer}>{props.languageContext.contactUs.upload}</div>
                    <CameraIcon selectedPhoto={selectedPhoto3.result}/>
                </div>
            </div>
            <br/>
            <button className={classes.submit} onClick={onSubmitEstimate}>{props.languageContext.contactUs.submit}</button>
        </div>
    );
}

export default EstimateForm;