import {Col, Container, Row} from "react-bootstrap";
import classes from "../styles/component_styles/reviews.module.scss";
import ReviewStars from "./ReviewStars";

function Review(props) {
    return(
        <div className={classes.review}>
            <Container fluid>
                <Row>
                    <Col>
                        <img src={require("../images/reviews/"+props.image)} className={classes.clientPhoto}/>
                        <p className={classes.clientName}>{props.name}</p>
                    </Col>
                    <Col>
                        <ReviewStars stars={props.stars}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className={classes.clientDescription}>{props.description}</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Review;