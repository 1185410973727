import React, {useState} from "react";
import classes from "../styles/component_styles/contactUs.module.scss";

function AppointmentForm(props) {

    const [appointmentName, setAppointmentName] = useState("");
    const [appointmentNumber, setAppointmentNumber] = useState("");
    const [appointmentDescription, setAppointmentDescription] = useState("");

    const onSubmitAppointment = () => {
        if((appointmentName != "") && (appointmentNumber != "") && (appointmentDescription != "")) {
            props.onSubmitAppointment(appointmentName, appointmentNumber, appointmentDescription);

            setAppointmentName("");
            setAppointmentNumber("");
            setAppointmentDescription("");
        }
    }

    return(
        <div key={"AppointmentForm"}>
            <input type="text" placeholder={props.languageContext.contactUs.namePlaceholder} value={appointmentName} onChange={e => setAppointmentName(e.target.value)} className={classes.text_input}/>
            <br/>
            <input type="number" placeholder={props.languageContext.contactUs.phonePlaceholder} value={appointmentNumber} onChange={e => setAppointmentNumber(e.target.value)} className={classes.text_input}/>
            <br/>
            <input type="text" placeholder={props.languageContext.contactUs.descriptionPlaceholder} value={appointmentDescription} onChange={e => setAppointmentDescription(e.target.value)} className={classes.text_input}/>
            <br/>
            <button className={classes.submit} onClick={onSubmitAppointment}>{props.languageContext.contactUs.submit}</button>
        </div>
    );
}

export default AppointmentForm;