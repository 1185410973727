import certificate from "../images/certificate.png";
import classes from "../styles/component_styles/certificate.module.scss";
import React from "react";

function Certificate() {
    return(
        <>
            <img src={certificate} className={classes.certificate}/>
        </>
    );
}

export default Certificate;