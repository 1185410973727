import React, {useContext} from 'react';
import {LanguageContext} from "../App";
import classes from "../styles/pages/portfolioPage.module.scss";
import {Container} from "react-bootstrap";
import PortfolioSlider from "../components/PortfolioSlider";
import {Helmet} from "react-helmet";

function PortfolioPage() {

    const languageContext = useContext(LanguageContext);

    return(
        <>

            <Helmet>
                <title>Portfolio of Home Remodeling Services in Charlotte NC</title>
                <meta
                    name="description"
                    content="Discover our portfolio of home remodeling services in Charlotte NC. From kitchen to bathroom renovations, we offer expert solutions to enhance your home."
                />
            </Helmet>

            <Container className={classes.portfolio_layout}>
                <h1>{languageContext.portfolioPage.title}</h1>
                {/*<p className={classes.info}>Just a few photos from my large collection..</p>*/}
                <PortfolioSlider/>
            </Container>
        </>
    );
}

export default PortfolioPage;