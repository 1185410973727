import React, {useContext} from "react";
import {LanguageContext} from "../App";
import classes from "../styles/pages/contactsPage.module.scss";
import {BsFillPersonFill, BsFillTelephoneFill} from "react-icons/bs";
import {MdEmail} from "react-icons/md";

function ContactInfo() {

    const languageContext = useContext(LanguageContext);

    return(
        <div className={classes.infoBlock}>
            <p><BsFillPersonFill className={classes.icon_name}/> {languageContext.aboutPage.name}</p>
            <p><BsFillTelephoneFill className={classes.icon_phone}/> <a href="tel:9803830161">9803830161</a></p>
            <p><MdEmail className={classes.icon_email}/> <a href="mailto:usahandyman.com@gmail.com?subject = usa-handyman.com">usahandyman.com@gmail.com</a></p>
            <p className={classes.info}>{languageContext.commonText.textBetter}</p>
        </div>
    );
}

export default ContactInfo;