import React, {useState} from "react";
import classes from "../styles/component_styles/contactUs.module.scss";
import {BsCamera} from "react-icons/bs";
import SelectStars from "./SelectStars";

function WriteReviewForm(props) {

    const [reviewName, setReviewName] = useState("");
    const [reviewNumber, setReviewNumber] = useState("");
    const [reviewEmail, setReviewEmail] = useState("");
    const [reviewDescription, setReviewDescription] = useState("");
    const [reviewStars, setReviewStars] = useState(0);

    const photo1 = React.createRef();

    const [selectedPhoto, setSelectedPhoto] = useState({result: null, file: null});

    const handlePhotoUpload = (event, setSelectedPhoto) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            const newObj = {result: e.target.result, file: file};
            setSelectedPhoto(newObj);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const UploadedFile = ({selectedPhoto}) => {
        if(selectedPhoto == null){
            return(
                <div className={classes.avatar_zone}></div>
            );
        }else{
            return(
                <div className={classes.avatar_zone} style={{ background: `url(${selectedPhoto}) center/cover no-repeat` }}></div>
            );
        }
    }

    const CameraIcon = ({selectedPhoto}) => {
        if(selectedPhoto == null){
            return(
                <BsCamera className={classes.camera_icon}/>
            );
        }else{
            return(
                <BsCamera className={classes.camera_icon} style={{ opacity: "0" }}/>
            );
        }
    }

    const onSubmitReview = () => {
        if((reviewName != "") && (reviewNumber != "") && (reviewEmail != "") && (reviewDescription != "") && (selectedPhoto.file != null) && (reviewStars != 0)) {
            const photo = {
                ...selectedPhoto
            };

            props.onSubmitReview(reviewName, reviewNumber, reviewEmail, reviewDescription, photo, reviewStars);

            setReviewName("");
            setReviewNumber("");
            setReviewEmail("");
            setReviewDescription("");
            setReviewStars(0)

            const resetObj = {result: null, file: null};
            setSelectedPhoto(resetObj);
        }
    }

    return(
        <div key={"reviewForm"}>
            <input type="text" placeholder={props.languageContext.contactUs.namePlaceholder} value={reviewName} onChange={e => setReviewName(e.target.value)} className={classes.text_input}/>
            <br/>
            <input type="number" placeholder={props.languageContext.contactUs.phonePlaceholder} value={reviewNumber} onChange={e => setReviewNumber(e.target.value)} className={classes.text_input}/>
            <br/>
            <input type="email" placeholder={props.languageContext.contactUs.emailPlaceholder} value={reviewEmail} onChange={e => setReviewEmail(e.target.value)} className={classes.text_input}/>
            <br/>
            <input type="text" placeholder={props.languageContext.contactUs.reviewDescriptionPlaceholder} value={reviewDescription} onChange={e => setReviewDescription(e.target.value)} className={classes.text_input}/>
            <br/>
            <SelectStars reviewStars={reviewStars} setReviewStars={setReviewStars}/>
            <br/>
            <div className={classes.files}>
                <div className={classes.file_input}>
                    <UploadedFile selectedPhoto={selectedPhoto.result}/>
                    <input type="file" ref={photo1} className={classes.upload_btn} accept="image/png, image/jpeg, image/jpg, image/gif, image/tif" onChange={(e) => handlePhotoUpload(e, setSelectedPhoto)}/>
                    <div className={classes.overlay_layer}>{props.languageContext.contactUs.upload}</div>
                    <CameraIcon selectedPhoto={selectedPhoto.result}/>
                </div>
            </div>
            <br/>
            <button className={classes.submit} onClick={onSubmitReview}>{props.languageContext.contactUs.submit}</button>
        </div>
    );
}

export default WriteReviewForm;