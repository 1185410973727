import React, {useContext} from "react";
import {LanguageContext} from "../../App";
import classes from "../../styles/component_styles/services.module.scss";
import image from "../../images/services/Carpentry.jpg";
import {FaPencilRuler} from "react-icons/fa";
import {Col, Container, Row} from "react-bootstrap";
import {Helmet} from "react-helmet";

function CarpentryPage() {

    const languageContext = useContext(LanguageContext);

    return(
        <>

            <Helmet>
                <title>Carpentry Services in Charlotte NC</title>
                <meta
                    name="description"
                    content="Explore top-notch carpentry services in Charlotte NC. From custom furniture to home repairs, our skilled carpenters deliver expert solutions for your needs."
                />
            </Helmet>

            <div className={classes.start_image}>
                <h1 className={classes.start_message}><FaPencilRuler className={classes.servicesIcon}/> {languageContext.services.CarpentryPage.title}</h1>
                <p className={classes.darkText}>{languageContext.services.CarpentryPage.description}</p>
            </div>

            <Container>
                <Row>
                    <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                        <p className={classes.description}>
                            {languageContext.services.CarpentryPage.text1}
                            <br/><br/>
                            <span>{languageContext.services.CarpentryPage.text2}</span>
                            <br/>
                            {languageContext.services.CarpentryPage.text3}
                            <br/><br/>
                            <span>{languageContext.services.CarpentryPage.text4}</span>
                            <br/>
                            {languageContext.services.CarpentryPage.text5}
                            <br/><br/>
                            <span>{languageContext.services.CarpentryPage.text6}</span>
                            <br/>
                            {languageContext.services.CarpentryPage.text7}
                            <br/><br/>
                            <span>{languageContext.services.CarpentryPage.text8}</span>
                            <br/>
                            {languageContext.services.CarpentryPage.text9}
                        </p>
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                        <img src={image} className={classes.image}/>
                    </Col>
                </Row>
            </Container>
        </>
    );

}

export default CarpentryPage;